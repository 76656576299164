<template>
    <!-- Categories -->
    <div class="card category-widget">
        <div class="card-header">
            <h4 class="card-title">Blog Categories</h4>
        </div>
        <div class="card-body">
            <ul class="categories">
                <li><a href="#">Cardiology <span>(62)</span></a></li>
                <li><a href="#">Health Care <span>(27)</span></a></li>
                <li><a href="#">Nutritions <span>(41)</span></a></li>
                <li><a href="#">Health Tips <span>(16)</span></a></li>
                <li><a href="#">Medical Research <span>(55)</span></a></li>
                <li><a href="#">Health Treatment <span>(07)</span></a></li>
            </ul>
        </div>
    </div>
    <!-- /Categories -->
</template>