<template>
	<!-- Category Section -->
		<section class="section section-category">
			<div class="container">
				<div class="section-header text-center">
					<h2>Browse by Specialities</h2>
					<p class="sub-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
				</div>
				<div class="row">
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/1.png" alt="">
							</div>
							<div class="category-content">
								<h4>Urology</h4>
								<span>21 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/2.png" alt="">
							</div>
							<div class="category-content">
								<h4>Neurology</h4>
								<span>18 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/3.png" alt="">
							</div>
							<div class="category-content">
								<h4>Orthopedic</h4>
								<span>17 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/4.png" alt="">
							</div>
							<div class="category-content">
								<h4>Cardiologist</h4>
								<span>12 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/5.png" alt="">
							</div>
							<div class="category-content">
								<h4>Dentist</h4>
								<span>07 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/1.png" alt="">
							</div>
							<div class="category-content">
								<h4>Urology</h4>
								<span>16 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/4.png" alt="">
							</div>
							<div class="category-content">
								<h4>Cardiologist</h4>
								<span>18 Doctors</span>
							</div>
						</div>
					</div>
					<div class="col-lg-3">
						<div class="category-box">
							<div class="category-image">
								<img src="../assets/img/category/3.png" alt="">
							</div>
							<div class="category-content">
								<h4>Neurology</h4>
								<span>22 Doctors</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	<!-- Category Section -->
</template>