<template>
    <!-- Clinic and Specialities -->
            <section class="section section-specialities">
                <div class="container-fluid">
                    <div class="section-header text-center">
                        <h2>Categories</h2>
                        <p class="sub-title">Wide range of categories of products are availabe through us.</p>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-md-9">
                            <!-- Slider -->
                            <div class="specialities-slider slider">
                            
                                <!-- Slider Item -->
                                <div class="speicality-item text-center">
                                    <div class="speicality-img">
                                        <img src="../assets/img/specialities/medicine.png" class="img-fluid" alt="Speciality">
                                        <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <p>Allopathy</p>
                                </div>  
                                <!-- /Slider Item -->
                                
                                <!-- Slider Item -->
                                <div class="speicality-item text-center">
                                    <div class="speicality-img">
                                        <img src="../assets/img/specialities/herbal-treatment.png" class="img-fluid" alt="Speciality">
                                        <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <p>Ayurvedic</p>    
                                </div>                          
                                <!-- /Slider Item -->
                                
                                <!-- Slider Item -->
                                <div class="speicality-item text-center">
                                    <div class="speicality-img">
                                        <img src="../assets/img/specialities/supplementary-food.png" class="img-fluid" alt="Speciality">
                                        <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                    </div>  
                                    <p>Fitness & Suppliments</p>   
                                </div>                          
                                <!-- /Slider Item -->
                                
                                <!-- Slider Item -->
                                <div class="speicality-item text-center">
                                    <div class="speicality-img">
                                        <img src="../assets/img/specialities/health-care-products.png" class="img-fluid" alt="Speciality">
                                        <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                    </div>  
                                    <p>Healthcare products</p> 
                                </div>                          
                                <!-- /Slider Item -->
                                
                                <!-- Slider Item -->
                                                             <div class="speicality-item text-center">
                                    <div class="speicality-img">
                                        <img src="../assets/img/specialities/herbal-treatment.png" class="img-fluid" alt="Speciality">
                                        <span><i class="fa fa-circle" aria-hidden="true"></i></span>
                                    </div>
                                    <p>Ayurvedic</p>    
                                </div>  
                           
                                <!-- /Slider Item -->
                                
                            </div>
                            <!-- /Slider -->
                            
                        </div>
                    </div>
                </div>   
            </section>   
            <!-- Clinic and Specialities -->
</template>
<script>
const images = require.context('../assets/img/specialities', false, /\.png$|\.jpg$/)
export default {
   mounted() {

// Slick Slider
    
    if($('.specialities-slider').length > 0) {
        $('.specialities-slider').slick({
            dots: true,
            autoplay:false,
            infinite: true,
            variableWidth: true,
            prevArrow: false,
            nextArrow: false
        });
    }

},
    methods: {
        loadImg(imgPath) {
            return images('./' + imgPath).default
        },
        
    },
}
</script>