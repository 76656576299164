<template>
    <!-- Search -->
        <div class="card search-widget">
            <div class="card-body">
                <form class="search-form">
                    <div class="input-group">
                        <input type="text" placeholder="Search..." class="form-control">
                        <div class="input-group-append">
                            <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    <!-- /Search -->
</template>