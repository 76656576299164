<template>
	<!--Top Header -->
	<div class="header-top">
		<div class="left-top ">
			<ul>
				<li><i class="fas fa-map-marker-alt top-icon"></i>123, washington street, uk</li>
				<li><i class="fas fa-phone-volume top-icon"></i>+19 123-456-7890</li>
				<li><i class="fas fa-envelope top-icon"></i>mail@yourdomain.com</li>
			</ul>
		</div>
		<div class="right-top ">
			<ul>
				<li><i class="fab fa-facebook-f top-icons"></i>
				</li>
				<li><i class="fab fa-instagram top-icons"></i>
				</li>
				<li><i class="fab fa-linkedin-in top-icons"></i>
				</li>
				<li><i class="fab fa-twitter top-icons"></i>
				</li>
			</ul>
		</div>
	</div>
	<!--/Top Header -->
</template>