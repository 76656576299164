<template>
   	<!-- Main Wrapper -->
       <div class="main-wrapper" style="align-items: center;
       color: #1f1f1f;
       display: flex;">
			
            <div class="error-box">
				<h1>500</h1>
				<h3 class="h2 mb-3"><i class="fa fa-warning"></i> Oops! Something went wrong</h3>
				<p class="h4 font-weight-normal">The page you requested was not found.</p>
				<router-link to="/admin/index" class="btn btn-primary">Back to Home</router-link>
			</div>
    
    </div>
    <!-- /Main Wrapper -->
</template>