<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb39></breadcrumb39>
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="terms-content">
              <div class="terms-text">
                <p class="h1">
                  <strong><u>PRIVACY POLICY</u></strong>
                </p>

                <p>
                  CentDeal Private Limited
                  (&ldquo;<strong>Company</strong>&rdquo; or
                  &ldquo;<strong>We</strong>&rdquo; or
                  &ldquo;<strong>Our</strong>&rdquo; or
                  &ldquo;<strong>Us</strong>&rdquo;) takes the privacy of your
                  information seriously. This Privacy Policy describes the types
                  of personal information we collect from you through our
                  website (including sub-domains and microsites) and mobile
                  applications. It also describes the purposes for which we
                  collect that personal information, the other parties with whom
                  we may share it and the measures we take to protect the
                  security of your data. It also tells you about your rights and
                  choices with respect to your personal information, and how you
                  can contact us about our privacy practices.
                </p>
                <p>
                  The &ldquo;User&rdquo;
                  (<strong>&ldquo;You&rdquo;</strong>&nbsp;or&nbsp;<strong>&ldquo;Your&rdquo;</strong>&nbsp;or
                  the&nbsp;<strong>&ldquo;User&rdquo;</strong>) shall mean and
                  include but not limited to the visitors who visit the Website,
                  the registered or unregistered visitors who provide
                  credentials or not on our Website, whether they have logged
                  into their account or not, whether the visitor is an active
                  user or not in the sole opinion of CentDeal Private Limited.
                </p>
                <p>
                  The Website is a platform that facilitates the online purchase
                  of Pharmaceutical products, Ayurvedic, Homeopathy, Cosmetic,
                  Health Care, Wellness and FMCG products for sale
                  by&nbsp;CentDeal Private Limited through Third Party
                  Pharmacies/ Vendors.
                </p>
                <p>
                  You are advised to carefully read this Privacy Policy before
                  using or availing any of our products and/or services. By
                  accessing or using this website / app, you agree to be bound
                  by the terms described herein and all the terms incorporated
                  by reference.&nbsp;
                </p>
                <p><strong>&nbsp;</strong></p>
                <!-- <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Pellentesque vel sodales mauris. Nunc accumsan mi massa, ut
                  maximus magna ultricies et:
                </p>
                <ol>
                  <li>
                    Integer quam odio, ullamcorper id diam in, accumsan
                    convallis libero. Duis at lacinia urna.
                  </li>
                  <li>
                    Mauris eget turpis sit amet purus pulvinar facilisis at sed
                    lacus.
                  </li>
                  <li>
                    Quisque malesuada volutpat orci, accumsan scelerisque lorem
                    pulvinar vitae.
                  </li>
                  <li>
                    Vestibulum sit amet sem aliquam, vestibulum nisi sed,
                    sodales libero.
                  </li>
                </ol> -->
              </div>
              <div class="terms-text">
                <h4>PERSONAL INFORMATION</h4>
                <p>
                  Personal information is that information which can be used to
                  directly or indirectly identify you. It includes de-identified
                  data that, when linked to other information available to us,
                  would enable us to identify you. Personal data does not
                  include data that has been irreversibly anonymized or
                  aggregated so that we cannot identify you through it, even in
                  conjugation conjunction with other information.
                </p>

                <p>
                  &ldquo;Sensitive Personal Data or Information&rdquo; means
                  Personal Information of any individual relating to password;
                  financial information such as bank account or credit card or
                  debit card or other payment instrument details; physical,
                  physiological and mental health condition; sexual orientation;
                  medical records and history; biometric information; any detail
                  relating to the above as provided to or received by Us for
                  processing or storage. However, any data/ information relating
                  to an individual that is freely available or accessible in
                  public domain or furnished under the Right to Information Act,
                  2005 or any other law shall not qualify as Sensitive Personal
                  Data or Information.
                </p>
                <p>
                  By clicking "I accept" when downloading the App or proceeding
                  to the Website, and/or using our services you represent that
                  you voluntarily provide us with personal information including
                  medical and financial information and consent to their
                  collection, use and disclosure in accordance with this Privacy
                  Policy. You also represent that you are duly authorised by any
                  third party (including a child or an employer) whose
                  information you share with us. We shall act as per your
                  representation of authority and shall not make any independent
                  enquiries to ascertain the veracity of your authorisation. In
                  the event you do not have sufficient authorisation you shall
                  be solely responsible for your acts and omissions including
                  sharing of information with us by you and the consequential
                  processing and actions taken by us in accordance with this
                  Privacy Policy.
                </p>
                <p>&nbsp;</p>
              </div>
              <div class="terms-text">
                <h4>TYPES OF DATA COLLECTED</h4>
                <li><u>Information you give us:</u></li>
                <p>
                  When you sign up or register on our App or Website to use our
                  Services, and during the course of actual usage of our
                  Services, the types of information that will be collected by
                  us include the following:
                </p>
                <ul>
                  <li>
                    Contact information: Name, Address, Contact details, Email
                    ID, Phone Number;
                  </li>
                  <li>
                    Demographic information: Gender, Age, Date of Birth, Marital
                    Status, Nationality;
                  </li>
                  <li>
                    Data regarding your usage such as search history made by you
                    through the use of Services;
                  </li>
                  <li>
                    Any other detail relating to the above you voluntarily
                    provide or is generated on usage of any of the services
                    availed by you, or for providing value added service;
                  </li>
                  <li>
                    Physical, physiological and mental health condition which
                    you voluntarily provide or is generated on usage of our
                    services through the app/website;
                  </li>
                  <li>
                    Data regarding your medical qualifications, registrations
                    and certifications;
                  </li>
                  <li>
                    Any additional information that you voluntarily choose to
                    provide through any mode of communication or during any
                    interaction.
                  </li>
                </ul>
                <p>
                  In order to avail the Services, the Users may be required to
                  upload copies of their prescriptions, on the Website and/ or
                  e-mail the same to Us in accordance with the Terms of Use and
                  the prescriptions will be stored/ disclosed by CentDeal
                  Private Limited only in the manner specified in this Privacy
                  Policy and the Terms of Use. The term &ldquo;User
                  Information&rdquo; shall also include any such prescriptions
                  uploaded or otherwise provided by Users.
                </p>
                <p>&nbsp;</p>
              </div>
              <div class="terms-text">
                <h4>Information from Other Sources</h4>
                <p>
                  We might receive information about You such as order details,
                  Your details shared with Our partners etc. from other sources
                  including from Our partners, advertisers or third parties
                  registered on the Platform and add it to Our account
                  information.
                </p>
                <p>&nbsp;</p>
                <ol>
                  <li><u>Cookies</u></li>
                </ol>
                <p>
                  Our Company, like many other websites uses
                  &ldquo;Cookies&rdquo;. Cookies are small data files that a
                  website stores on your web browser. These are used for the
                  purpose of storing your preferences, previous activities
                  browsing activities, profiling and tracking behaviour on this
                  Website. By visiting the Website, you acknowledge, accept and
                  expressly authorize the placement of cookies on your web
                  browser. You may choose to set Your web browser to refuse
                  cookies, or to alert You when cookies are being sent. If they
                  do so, note that some parts of the Website may not function
                  properly.
                </p>
                <p>&nbsp;</p>
                <ol>
                  <li><u>Third party tools and software</u><u></u></li>
                </ol>
                <p>
                  We use third party SDKs in Our App. Majority of them are
                  different payment options by which You can make a payment for
                  an order. In order to enhance Your personal and overall
                  experience some of the tools are used therein.
                </p>
                <p>
                  The IT Act and the SPDI Rules regulate the collection, usage,
                  retention and disclosure of personal information, which is
                  defined under the SPDI Rules as any information that relates
                  to a natural person, which, either directly or indirectly, in
                  combination with other information available or likely to be
                  available to a body corporate, is capable of identifying such
                  person.
                </p>
                <p>
                  You agree that You are providing all information, including
                  SPDI to Us voluntarily. Collection, use and disclosure of
                  personal information and SPDI requires Your express consent.
                </p>
                <p>
                  You are providing Us with Your consent to Our use, collection
                  and disclosure of the personal information and SPDI. You may
                  choose to not provide Us with personal information and SPDI,
                  but in the event that You do so, We will be unable to provide
                  You access to Our Website or deliver products or provide
                  Services through Our Website.
                </p>
                <p>&nbsp;</p>
              </div>
              <div class="terms-text">
                <h4>HOW IS THE INFORMATION COLLECTED USED</h4>

                <p>
                  Information about You and the other Users of the Website forms
                  an integral part of Our business. We only share the personal
                  information as described below after ensuring that such third
                  party has implemented measures to assure data protection
                  measures and that are either subject to this Privacy Policy or
                  follow practices at least as protective as those described in
                  this Privacy Policy. By using the Website or the App, You
                  accept the terms hereof and hereby consent to the storage and
                  processing of the personal information and SPDI by third
                  parties. We, have arrangements with third parties (including
                  as mentioned below) such as affiliates or group companies,
                  service providers, retailers, Retail Pharmacies, payment
                  gateways, logistics partners who are the intended recipients
                  and may have access to the personal information and SPDI but
                  shall not disclose such information including SPDI further to
                  any other individual / entity. Some of the third parties
                  include:
                </p>
                <ol>
                  <li>
                    <u>Retail partners</u><strong>:</strong>Some of Your
                    personal information or SPDI will be shared with affiliated
                    retailers, the Retail Pharmacies who supply and service Your
                    order and with the other third parties if required. These
                    retailers operate on the Website or sell medicines and
                    healthcare products, surgical products, wellness products
                    and FMCG (Fast moving Consumer Goods) products to You on the
                    Site or the App. You can identify when a third party is
                    involved in Your transactions, and We share customer
                    information related to those transactions with that third
                    party;
                  </li>
                  <li>
                    <u>Third Party Service Providers</u><strong>:</strong>Some
                    of Your personal information or SPDI may be shared with
                    third party service providers, such as retailers, and
                    logistics providers for the fulfilment of Services and
                    delivery of Your order(s). Examples include fulfilling
                    orders, collection and processing, delivering packages,
                    sending postal mail, newsletters and e-mail, removing
                    repetitive information from customer lists, analysing data,
                    providing marketing assistance, providing search results and
                    links (including paid listings and links), processing card
                    payments and providing customer service. The tools used by
                    such third party service providers to provide the Services,
                    may also collect Your personal information or SPDI during
                    the process of providing such Services. Further, they must
                    process the personal information in accordance with this
                    Privacy Policy and as permitted by applicable law. It is
                    clarified that We will not be responsible and liable for the
                    acts of omissions and commissions of such third parties
                    associated with Us. However, We may facilitate resolving any
                    issue You may face with such third parties;
                  </li>
                  <li>
                    <u>Our Legal obligations</u><strong>:</strong>We may release
                    account and other personal information when We believe in
                    good faith that such release is appropriate to comply with
                    applicable law including to: (i) conform to legal
                    requirements or comply with legal process; (ii) protect
                    rights or property or affiliated companies; (iii) prevent a
                    crime or in interest of national security; or (iv) protect
                    personal safety of Our Users or the public. We may also
                    disclose Your personal information to enforce or apply Our
                    Terms and other agreements; or protect the rights, property
                    or Our safety, safety of Our Users or others. This includes
                    exchanging information with other companies, organisations,
                    government or regulatory authorities for fraud protection
                    and credit risk reduction;
                  </li>
                  <li>
                    <u
                      >Sharing upon merger or amalgamation or intra-group
                      transfer</u
                    >: Any third party to which We transfer or sell Our assets,
                    merge or consolidate with, will have the right to continue
                    to use the information (including SPDI) provided to Us by
                    You, in accordance with the Terms and this Privacy Policy.
                    We may disclose information to Our partners, affiliates,
                    subsidiaries, group entities, investors, stakeholders or
                    potential associates in an anonymized and aggregate manner,
                    so that they too may understand how Users use Our Website
                    and enable Us to create a better overall experience for You;
                    and
                  </li>
                  <li>
                    <u>Improving Our business</u>: You acknowledge that We have
                    a right to use data provided by You for improving Our
                    Services, marketing and promotional efforts and customize
                    Your experience. These uses improve the Site, the App, and
                    the Services, and better tailor it to meet Your needs, so as
                    to provide You with an efficient, safe and customized
                    experience. We may transfer such personal Information and
                    SPDI to a third party, including persons outside India, to
                    improve product and Service offerings while taking
                    commercially reasonable steps to try and ensure, that the
                    recipient adheres to the applicable laws for ensuring data
                    protection as is adhered by Us.
                  </li>
                </ol>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>SECURITY PRACTICES</h4>
                <p>
                  The Company adopts reasonable security practices and
                  procedures to include, technical, operational, managerial and
                  physical security control measures in order to protect the
                  Personal Information in its possession from loss, misuse and
                  unauthorized access, disclosure, alteration and destruction.
                  We take adequate steps to ensure that third parties to whom
                  the Users&rsquo; Sensitive Personal Data or Information may be
                  transferred adopt reasonable level of security practices and
                  procedures to ensure security of Personal Information.
                </p>
                <p>&nbsp;</p>
                <p>
                  You hereby acknowledge that the Company is not responsible for
                  any intercepted information sent via the internet, and you
                  hereby release us from any and all claims arising out of or
                  related to the use of intercepted information in any
                  unauthorized manner.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>MINORS</h4>
                <p>
                  Our website and mobile application do not offer products or
                  services for use by minors. If you are under 18, you may use
                  our website or mobile application only with the involvement of
                  a parent or guardian.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>DATA SECURITY</h4>
                <p>
                  We will use technical and organisational measures to safeguard
                  your Data and we store your Data on secure servers. Technical
                  and organisational measures include measures to deal with any
                  suspected data breach. If you suspect any misuse or loss or
                  unauthorised access to your Data, please let us know
                  immediately by contacting us on our registered e-mail.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>RETENTION OF DATA</h4>
                <p>
                  We store your personal information in accordance with
                  applicable laws, which means we keep your data for as long as
                  necessary to provide you with our Services or as may be
                  required under any law. We shall store your personal
                  information for lawful purposes only. We keep de-identified
                  data for research and statistical purposes for a longer
                  period. Data collected under this Privacy Policy is hosted on
                  servers located in India.
                </p>
                <p>
                  If you close your account, we have no obligation to retain
                  your data, and we may delete any or all of your data without
                  liability. However, we may retain data related to you if we
                  believe it may be necessary to prevent fraud or future abuse,
                  or if required by law, or for other legitimate purposes. We
                  may continue to store your data in anonymized form for
                  analytical and research purposes.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>YOUR RIGHTS AND CHOICES</h4>
                <p>
                  When we process Data about you, we do so with your consent
                  and/or as necessary to operate our business, meet our
                  contractual and legal obligations, protect the security of our
                  systems and our customers, or fulfil other legitimate
                  interests of the Company as described in this Privacy Policy.
                  You have the following rights in relation to your sensitive
                  personal information and which can be exercised:
                </p>
                <ul>
                  <li>Right to Access, Review and Modify</li>
                  <li>Right to Correction</li>
                  <li>Right to Withdraw Consent</li>
                </ul>
                <p>
                  It is important that the Data we hold about you is accurate
                  and current. Please keep us informed if your personal
                  information changes during the period for which we hold it.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>DISCLOSURE AND TRANSFER OF YOUR DATA</h4>
                <p>
                  We may disclose and in some cases transfer your personal
                  information to such entities as required to provide services
                  to you and to provide value added services or other third
                  party products and services, to the extent permitted by
                  applicable law. These entities may be located outside India,
                  which you hereby consent to. We require such entities to
                  protect your information through equivalent security measures
                  as what we would adopt. An indicative list of entities we may
                  disclose or transfer information to, are provided below:
                </p>
                <ol>
                  <li>
                    <u>Service Providers</u>: We share personal information with
                    companies that provide Services on our behalf, such as
                    website hosting, data storage, software services, email
                    services, marketing, fulfilling customer orders, providing
                    payment services, data analytics, data mining, providing
                    customer services, and conducting surveys. These companies
                    may be located within or outside India, but in any case are
                    obligated to protect your data.
                  </li>
                </ol>
                <p>
                  We may also share information with employees, data processors,
                  consultants, business partners and technology partners on a
                  need to know basis. Such entities would be contractually
                  obligated to maintain confidentiality in relation to your
                  data. If you are an end user, your personal information will
                  also be shared with your chosen HSPs.
                </p>
                <ol>
                  <li>
                    <u>Business Affiliates</u>: We may disclose or transfer some
                    of your information to entities, affiliates, associates,
                    subsidiary, holding company of the Company, associates and
                    subsidiary of holding company of the Company including
                    foreign entities, and in particular group companies and
                    affiliates who are involved in the provision of products and
                    services, to the extent permitted by applicable law.
                  </li>
                </ol>
                <p>
                  In the event of a merger, reorganization, acquisition, joint
                  venture, assignment, spin-off, transfer, asset sale, or sale
                  or disposition of all or any portion of our business,
                  including in connection with any bankruptcy or similar
                  proceedings, we may transfer any and all personal information
                  to the relevant third party with the same rights of access and
                  use.
                </p>
                <ol>
                  <li>
                    <u>Law Enforcement Agencies</u>: We may share information
                    with law enforcement agencies pursuant to lawful requests
                    for information, and otherwise as required under any law
                    applicable at the given time, both in India and outside
                    India.
                  </li>
                  <li>
                    <u>Other Third Parties</u>: We may also disclose personal
                    information if we determine in good faith that disclosure is
                    reasonably necessary to protect our rights and pursue
                    available remedies, enforce our terms and conditions,
                    investigate fraud, or protect our operations or users.
                    Anonymized, aggregated data that cannot re-identify You, may
                    be shared with advertisers, research firms and other
                    partners.
                  </li>
                </ol>
                <p>
                  Subject to applicable law, We may at Our sole discretion,
                  transfer personal information and SPDI to any other body
                  corporate (as defined under the Information Technology Act,
                  2000) that ensures at least the same level of data protection
                  as is provided by Us under the terms hereof, located in India
                  or any other country.
                </p>
                <p>
                  By using the Website, You accept the terms hereof and hereby
                  consent to Us, sharing with and/or processing of Your personal
                  information and SPDI by third parties, including in any
                  location outside India, provided that they ensure that Your
                  SPDI is protected in compliance with standards that are
                  comparable to the standards of protection afforded to it in
                  India or equivalent international standards.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>THIRD PARTY WEBSITES AND SERVICES</h4>
                <p>
                  Our Website and App may contain links to third party services,
                  and give you the ability to access such third-party websites,
                  products, and services. Please note that you may proceed to
                  the use of such third party website or service at your own
                  risk and the Company will not be held liable for any outcome
                  or harm arising as a result of your use of such third party
                  websites or services. Please read the privacy policies of any
                  third party before proceeding to use their websites, products,
                  or services.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>SECURITY</h4>
                <p>
                  We employ security technology as detailed in our privacy
                  policy however, Internet transmissions are never completely
                  private or secure and there is a risk, therefore, that any
                  message or information you send to us from this website may be
                  intercepted and potentially read by others. We will have no
                  liability in respect of any transmissions you send to us and
                  you do so entirely at your own risk.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>SEVERABILITY</h4>
                <p>
                  If any court or competent authority finds that any provision
                  of this Privacy Policy (or part of any provision) is invalid,
                  illegal or unenforceable, that provision or part-provision
                  will, to the extent required, be deemed to be deleted, and the
                  validity and enforceability of the other provisions of this
                  Privacy Policy will not be affected.
                </p>
              </div>

              <div class="terms-text">
                <h4>CHANGES TO THIS PRIVACY POLICY</h4>
                <p>
                  We may periodically revise or update this Privacy Policy. Your
                  continued use of our products and services after the effective
                  date of the Privacy Policy means that you accept the revised
                  Privacy Policy. If you do not agree with any such revised
                  terms, please refrain from using our Services and contact us
                  to close any account you may have created.
                </p>
                <p>&nbsp;</p>
              </div>

              <div class="terms-text">
                <h4>CONTACT US</h4>
                <p>
                  To request to access, review, update, or withdraw your consent
                  for your personal information or to otherwise reach us, please
                  submit a request by e-mailing us at
                  <b>support@pharmgo.in</b> You may contact us for information
                  on Service Providers, Partners and Entities with whom we may
                  share your Data in compliance with this Privacy Policy and
                  applicable law. We will respond to your request within 30
                  days.
                </p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>&nbsp;</strong></p>
              </div>

              <div class="terms-text">
                <h4>DISCLAIMER</h4>
                <p class="dis">
                  CENTDEAL DOES NOT WARRANT OR REPRESENT THAT THE MATERIAL ON
                  THIS WEBSITE/MOBILE APPLICATION IS ACCURATE, COMPLETE OR
                  CURRENT OR THAT THE WEBSITE WILL BE FREE OF DEFECTS OR
                  VIRUSES. NOTHING CONTAINED IN THE PAGES OF THIS WEBSITE/MOBILE
                  APPLICATION SHOULD BE CONSTRUED AS MEDICAL, COMMERCIAL, LEGAL
                  OR OTHER PROFESSIONAL ADVICE. DETAILED PROFESSIONAL ADVICE
                  SHOULD BE OBTAINED BEFORE TAKING OR REFRAINING FROM ANY ACTION
                  BASED ON ANY OF THE INFORMATION OR MATERIAL CONTAINED IN THIS
                  WEBSITE/MOBILE APPLICATION OR ANY COMMUNICATIONS PROVIDED TO
                  YOU AS A RESULT OF YOUR REGISTRATION.
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
