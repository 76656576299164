<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb38></breadcrumb38>
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="terms-content">
              <div class="terms-text">
                <u>SHIPPING, RETURN, CANCELLATION AND REFUND POLICY</u>
                <p>
                  <strong><u>&nbsp;</u></strong>
                </p>

                <p>
                  This Payment, Returns, Refund and Cancellation Policy sets out
                  the terms and conditions on the basis of which we, CentDeal
                  Private Limited (collectively referred to as
                  &ldquo;<strong>Company</strong>&rdquo; or
                  &ldquo;<strong>We</strong>&rdquo; or
                  &ldquo;<strong>Our</strong>&rdquo; or
                  &ldquo;<strong>Us</strong>&rdquo;) will process payments and
                  refunds for transactions carried on through your use of the
                  mobile application or the website (collectively referred to as
                  the &ldquo;Website&rdquo;) while availing services (detailed
                  in the Terms and Conditions) made available to you the Website
                  (the &ldquo;Services&rdquo;)
                </p>
                <ol>
                  <li class="heading"><strong>SHIPPING POLICY</strong></li>
                  <li>
                    <u> <strong>Shipment Processing Time</strong></u>
                  </li>
                </ol>
                <p>
                  We will try our best to get your products to you within the
                  estimated delivery times. If the package has not reached you
                  by the expected delivery date, please write to us
                  at&nbsp;<b>support@pharmgo.in</b>&nbsp;and we will try our
                  best to resolve your issues. If there will be a significant
                  delay in shipment of Your Order, We will contact You via email
                  or telephone.
                </p>
                <ol start="2">
                  <li>
                    <strong
                      ><u>Shipping Rates &amp; Delivery Estimates</u></strong
                    >
                  </li>
                </ol>
                <p>
                  Estimated shipping charges are calculated as per the value of
                  the order and can be viewed in the cart section at the time of
                  checkout. Delivery delays can occasionally occur.
                </p>
                <ol start="3">
                  <li>
                    <strong
                      ><u>Shipment Confirmation &amp; Order Tracking</u></strong
                    >
                  </li>
                </ol>
                <p>
                  You will receive a Shipment Confirmation Email/ Messages once
                  Your Order has shipped containing your tracking number(s).
                </p>
                <ol>
                  <li class="heading"><strong>RETURN POLICY</strong></li>
                  <li>
                    <strong><u>General</u></strong>
                  </li>
                </ol>
                <p>
                  Our team facilitates processing correct medicines as per order
                  and prescription and all other products as provided by us, and
                  strives to service the medicines and products in right
                  conditions/ without any damage every time a consumer places an
                  order. We also strongly recommend the items are checked at the
                  time of delivery.
                </p>
                <p>
                  If the product that you have received is damaged, then do not
                  accept the delivery of that product. If after opening the
                  package you discover that the product is damaged or the item
                  has already expired, the same may be returned for a refund.
                  Please note that we cannot promise a replacement for all
                  products as it will depend on the availability of the
                  particular product, in such cases we will offer a refund.
                </p>
                <p>
                  In the aforesaid unlikely situations, if there is something
                  wrong with the order, We will be happy to assist and resolve
                  your concern. You may raise a Return request by writing to us
                  at <b>support@pharmgo.in</b>&nbsp;along with appropriate image
                  attachments of your concern within 07 (Seven) days from the
                  delivery of the product. The Company reserves the right to
                  cancel the Return request, if the customer reaches out to Us
                  after 07 days of delivery.
                </p>
                <p>
                  Upon receiving your Return/Refund request, We shall verify the
                  authenticity and the nature of the request. If the Company
                  finds that the request is genuine, it will initiate the Return
                  and Refund process. The Company shall process the refund only
                  once it has received the confirmation from the vendor
                  concerned in respect of the contents of the product relating
                  to that refund.
                </p>
                <p>
                  In the event of frivolous and unjustified complaints regarding
                  the quality and content of the products, The Company reserves
                  the right to pursue necessary legal actions against You and
                  You will be solely liable for all costs incurred by Us in this
                  regard.
                </p>
                <ol start="2">
                  <li>
                    <strong><u>Conditions</u></strong>
                  </li>
                </ol>
                <p>The returns are subject to the below conditions:-</p>
                <ol>
                  <li>
                    Any wrong ordering of product doesn&rsquo;t qualify for
                    Return;
                  </li>
                  <li>
                    Batch number of the product being returned should match as
                    mentioned on the invoice;
                  </li>
                  <li>
                    Return requests arising due to change in prescription do not
                    qualify for Return;
                  </li>
                  <li>
                    Product being returned should only be in their original
                    manufacturer's packaging i.e. with original price tags,
                    labels, bar-code and invoice; and
                  </li>
                  <li>
                    Partially consumed strips or products do not qualify for
                    Return, only fully unopened strips or products can be
                    returned.
                  </li>
                  <li>
                    Original sales invoice has to be returned for processing
                    returns and refunds
                  </li>
                  <li>
                    <strong><u>Non-Returnable Products</u></strong>
                  </li>
                </ol>
                <p>
                  Certain categories of products marked as non- returnable on
                  product page, will not qualify for the Return as per Our
                  Return policy. The details of the non- returnable products are
                  mentioned below:
                </p>
                <table class="table">
                  <tbody>
                    <tr>
                      <td width="181">
                        <p><strong>CATEGORY</strong></p>
                      </td>
                      <td width="435">
                        <p><strong>TYPE OF PRODUCT</strong></p>
                      </td>
                    </tr>
                    <tr>
                      <td width="181">
                        <p>Food and Nutrition</p>
                      </td>
                      <td width="435">
                        <p>Health Drinks, Health Supplements</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="181">
                        <p>Health Care Product</p>
                      </td>
                      <td width="435">
                        <p>
                          Baby Care Products of all range, Adult diapers,
                          Antiseptic Liquids &amp; etc
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="181">
                        <p>Vitamin and Mineral Supplements</p>
                      </td>
                      <td width="435">
                        <p>Protein Powders, Protein Shakes, Protein Bars</p>
                      </td>
                    </tr>
                    <tr>
                      <td width="181">
                        <p>Temperature controlled medicines</p>
                      </td>
                      <td width="435">
                        <p>
                          Vials, Injections, Vaccines, Penfills and any other
                          Product, requiring cold storage, or medicines that
                          fall under the category of speciality medicines.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="181">
                        <p>Surgical items</p>
                      </td>
                      <td width="435">
                        <p>
                          Glucometer Lancet/Strip, Healthcare Devices and Kits,
                          Surgical, Health Monitors
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td width="181">
                        <p>Others</p>
                      </td>
                      <td width="435">
                        <p>
                          FMCG Products of any kind including Toothpastes,
                          Shampoos, Conditioners, Confectionaries, Toiletries,
                          Disinfectant Sprays, Room Fresheners, etc
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>&nbsp;</p>
                <p>
                  Items marked as "Non-Returnable" on the product detail page or
                  items whose return window has expired cannot be returned. If
                  such an item is indeed returned along with another returnable
                  item, We will disallow a refund or return of the
                  &ldquo;Non-Returnable&rdquo; product to you when our partner
                  licensed retail pharmacy verifies the returned item(s).
                </p>
                <ol start="4">
                  <li>
                    <strong><u>Products Not Eligible For Return</u></strong>
                  </li>
                </ol>
                <p>
                  As per terms offered by all the sellers on the Website, the
                  products shall not be eligible for a return under the
                  following circumstances-
                </p>
                <ol>
                  <li>
                    If the item has been opened, partially used or disfigured.
                    The user is strongly advised to check the package carefully
                    at the time of acceptance of products by the user from the
                    user&rsquo;s delivery agent;
                  </li>
                  <li>If the product has been tampered with;</li>
                  <li>
                    If the product packaging and/or packaging box and/or
                    packaging seal has been tampered with. The user is strongly
                    advised to check the package carefully at the time of
                    acceptance of products by the user from the user&rsquo;s
                    delivery agent and it is emphasized that the user should not
                    accept the order if the package appears to be tampered;
                  </li>
                  <li>
                    If it is mentioned on the product detail page that the
                    product is non-returnable;
                  </li>
                  <li>
                    Any accessories/freebies supplied with the product are
                    missing;
                  </li>
                  <li>
                    If the product does not have serial number / UPC number/
                    barcode affixed, which was present at the time of acceptance
                    of products by the user from the user&rsquo;s delivery
                    agent;
                  </li>
                  <li>
                    Any damage/defect which is not covered under the
                    manufacturer's warranty;
                  </li>
                  <li>
                    The product is without original packing and/ or accessories;
                  </li>
                  <li>If the product is damaged due to misuse;</li>
                  <li>
                    Products related to the aforesaid
                    &ldquo;non-returnable&rdquo; category. However, the Company
                    shall accept return if aforementioned products are
                    defective, deficient or spurious or not of the
                    characteristics or features as advertised or if they are
                    delivered late.
                  </li>
                  <li>
                    <strong><u>Return Process</u></strong>
                  </li>
                  <li>
                    For Return, Go to My Orders &agrave; Select the respective
                    order &agrave; click on &lsquo;RETURN&rsquo; and select
                    reason for return.
                  </li>
                </ol>
                <p>
                  <strong><em>OR</em></strong> You can also place a request for
                  return of any product by emailing us at
                  <b>support@pharmgo.in</b>. &nbsp;You will be notified if your
                  request is accepted.
                </p>
                <ol>
                  <li>
                    Once the claim is verified as genuine and reasonable, We
                    will initiate the collection of product(s) to be returned.
                  </li>
                  <li>
                    The customer will be required to pack the product(s) in
                    original manufacturer&rsquo;s packaging with all the labels
                    intact.
                  </li>
                  <li>
                    Returns will be accepted only within 07 days from the date
                    of delivery subject to storage and physical conditions of
                    the items or medicine purchased by You.
                  </li>
                  <li>
                    Refund will be completed within 7-10 business days from date
                    of reverse pick up (if required).
                  </li>
                </ol>
                <p>
                  <em
                    >* Refund timelines depend on bank turnaround times and RBI
                    guidelines. This may change from time to time. Business days
                    shall mean the working days on which CentDeal corporate
                    office operates.</em
                  >
                </p>
                <ol>
                  <li class="heading"><strong>CANCELLATION POLICY</strong></li>
                  <li>
                    <strong><u>Cancelling an Orders</u></strong>
                  </li>
                  <li>All Products</li>
                </ol>
                <p>
                  An order for the products available through the use of the
                  Website including but not limited to medical and healthcare
                  products, surgical products, wellness products and FMCG
                  products can be cancelled from the &lsquo;Order Details&rsquo;
                  screen on the website/ app or by emailing us at
                  <b>support@pharmgo.in</b> before it is marked as
                  &ldquo;Dispatched&rdquo;. Else, you can refuse it at the time
                  of delivery and a refund will be processed as per the Refund
                  Policy. All refund will be made only through NEFT to the
                  Account which is provided by the customer. Refund for credit
                  card payments will take 7-8 days to get processed.
                </p>
                <ol start="2">
                  <li>
                    <strong><u>Conditions</u></strong>
                  </li>
                </ol>
                <p>
                  In certain cases the orders that have already been dispatched
                  by us will not be cancelled in accordance with this policy.
                  The customer agrees not to dispute the decision made by
                  Centdeal and accept the Company&rsquo;s decision regarding the
                  cancellation.
                </p>
                <ol>
                  <li class="heading"><strong>REFUND POLICY</strong></li>
                  <li>
                    <strong><u>Refund Timeline</u></strong>
                  </li>
                  <li>
                    Please note that the refund amount mentioned at the time of
                    return on the Website is an estimate and will be finalised
                    after completion of verification. Once the pick-up is
                    completed and the product is verified by the seller, a
                    refund of the total amount paid for the products returned by
                    you will be initiated for eligible returns as mentioned
                    above.
                  </li>
                  <li>
                    Refund will be initiated only post successful verification
                    of the products by the seller.
                  </li>
                  <li>
                    Please note that the amount is expected to reflect in your
                    account after completion of verification from the seller.
                  </li>
                  <li>
                    You understand and acknowledge that we reserve the rights to
                    claim back any refund that has been processed by us as a
                    result of fraudulent activities such as return of wrong
                    products or wrong quantity of the products.
                  </li>
                  <li>
                    <strong><u>Additional Information</u></strong>
                  </li>
                  <li>
                    Refunds for all eligible returns or cancellations are issued
                    through the payment method used at the time of purchase,
                    except for cash payments made under the Pay on Delivery mode
                    of payment.
                  </li>
                  <li>
                    You will not receive cash refunds in connection with a card
                    sale.
                  </li>
                  <li>
                    Refunds cannot be processed to third-party accounts. The
                    name on your account should match with the name of the bank
                    account holder.
                  </li>
                  <li>
                    Refunds will only be made against genuine and trackable
                    cancellation or return requests generated by you by clicking
                    on the cancellation button provided on the Website under
                    your account. Refund request against delays will not be
                    entertained.
                  </li>
                  <li>
                    The Company will not be liable for any delay caused in
                    refunds due to delay by third party affiliates (including
                    banks), in providing information by the customer, technical
                    issues and other reasons beyond its control.
                  </li>
                  <li>
                    The estimated time period for a refund is 7-10 business
                    days.
                  </li>
                  <li>
                    All communications with regards to refund should be sent to
                    our registered email id <b>support@pharmgo.in</b>.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <layout-footer></layout-footer>
  </div>
</template>
