<template>
    <!-- Tags -->
    <div class="card tags-widget">
        <div class="card-header">
            <h4 class="card-title">Tags</h4>
        </div>
        <div class="card-body">
            <ul class="tags">
                <li><a href="#" class="tag">Children</a></li>
                <li><a href="#" class="tag">Disease</a></li>
                <li><a href="#" class="tag">Appointment</a></li>
                <li><a href="#" class="tag">Booking</a></li>
                <li><a href="#" class="tag">Kids</a></li>
                <li><a href="#" class="tag">Health</a></li>
                <li><a href="#" class="tag">Family</a></li>
                <li><a href="#" class="tag">Tips</a></li>
                <li><a href="#" class="tag">Shedule</a></li>
                <li><a href="#" class="tag">Treatment</a></li>
                <li><a href="#" class="tag">Dr</a></li>
                <li><a href="#" class="tag">Clinic</a></li>
                <li><a href="#" class="tag">Online</a></li>
                <li><a href="#" class="tag">Health Care</a></li>
                <li><a href="#" class="tag">Consulting</a></li>
                <li><a href="#" class="tag">Doctors</a></li>
                <li><a href="#" class="tag">Neurology</a></li>
                <li><a href="#" class="tag">Dentists</a></li>
                <li><a href="#" class="tag">Specialist</a></li>
                <li><a href="#" class="tag">Doccure</a></li>
            </ul>
        </div>
    </div>
    <!-- /Tags -->
</template>