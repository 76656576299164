<template>
  <div class="main-wrapper">
    <layout-header></layout-header>
    <breadcrumb38></breadcrumb38>
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="terms-content">
              <div class="terms-text">
                <u>TERMS &amp; CONDITIONS</u>
                <p>
                  This document (&ldquo;Terms&rdquo;) is an electronic record in
                  terms of Information Technology Act, 2000 and rules
                  thereunder, as applicable and the amended provisions
                  pertaining to electronic records in various statutes, as
                  amended from time to time by the Information Technology Act,
                  2000. This electronic record is generated by a computer system
                  and does not require any physical or digital signatures.
                </p>
                <p>
                  This document is published in accordance with the provisions
                  of Rule 3(1) of the Information Technology (Intermediaries
                  Guidelines and Digital Media Ethics Code) Rules, 2021 that
                  requires publishing of the rules and regulations, privacy
                  policy and Terms for access or usage of www.pharmgo.in website
                  and its mobile applications.
                </p>
                <p>
                  Please read these terms of use carefully by accessing or using
                  this internet based platform, you agree to be bound by the
                  terms described herein and all terms incorporated by
                  reference.
                </p>
                <p>&nbsp;</p>
              </div>
              <div class="terms-text">
                <h4>GENERAL</h4>
                <p>
                  The domain name <em>www.pharmgo.in</em>, an internet based
                  portal and <em>Pharmgo</em>, a mobile application, is operated
                  by CentDeal Private Limited, a company duly incorporated under
                  the provisions of the Companies Act, 2013 (hereinafter
                  referred to as &ldquo;<strong>Company</strong>&rdquo; or
                  &ldquo;<strong>We</strong>&rdquo; or
                  &ldquo;<strong>Our</strong>&rdquo; or
                  &ldquo;<strong>Us</strong>&rdquo;) having registered office at
                  Barrack No. 631, Room No 57, Karo Hall Khemani Bus Stop,
                  Ulhasnagar &ndash; 2, Ulhasnagar Thane, Mh-421002, IN. The
                  domain name and the mobile application are collectively
                  referred to as the &ldquo;<strong>Website</strong>&rdquo;.
                </p>
                <p>
                  The Website is a platform that facilitates the online purchase
                  of Pharmaceutical products, Ayurvedic, Homeopathy, Cosmetic,
                  Health Care, Wellness and FMCG products for sale
                  by&nbsp;CentDeal Private Limited through Third Party
                  Pharmacies/ Vendors. Please note that the Company is not the
                  direct seller of any products/drugs and instead only
                  facilitates to bridge the gap in a digital way between the
                  User / Customer and third party shops/ pharmacies.
                  (hereinafter referred to as &ldquo;<strong
                    >Third Party Service Providers</strong
                  >&rdquo;)
                </p>
                <p>
                  Your access or use of the Website, transaction on the Website
                  and use of Services (as defined herein below) hosted or
                  managed remotely through the Website, are governed by the
                  following terms and conditions (hereinafter referred to as
                  the&nbsp;<strong>Terms of Use</strong>&rdquo;), including the
                  applicable policies which are incorporated herein by way of
                  reference. These Terms of Use constitutes a legal and binding
                  contract between you (hereinafter referred to
                  as&nbsp;<strong>&ldquo;You&rdquo;</strong>&nbsp;or&nbsp;<strong>&ldquo;Your&rdquo;</strong>&nbsp;or
                  the&nbsp;<strong>&ldquo;User&rdquo;</strong>) on one part and
                  the Company on the other Part.
                </p>
                <p>
                  By accessing, browsing or in any way transacting on the
                  Website, or availing any Services, You signify Your agreement
                  to be bound by these Terms of Use. Further, by impliedly or
                  expressly accepting these Terms of Use, you also accept and
                  agree to be bound by Our policies, including the Privacy
                  Policy, and such other rules, guidelines, policies, terms and
                  conditions as are relevant under the applicable law(s) in
                  India and other jurisdictions for the purposes of accessing,
                  browsing or transacting on the Website, or availing any of the
                  Services, and such rules, guidelines, policies, terms and
                  conditions shall be deemed to be incorporated into, and
                  considered as part and parcel of these Terms of Use. However,
                  if You navigate away from the Website to a third party
                  website, You may be subject to alternative terms and
                  conditions of use and privacy policy, as may be specified on
                  such website. In such event, the terms and conditions of use
                  and privacy policy applicable to that website will govern Your
                  use of that website.
                </p>
                <p>
                  Our Website is operated, and services are provided in
                  compliance with the laws in India and Company shall not be
                  liable to deliver any Products or provide any Services
                  purchased by You in locations outside India. If you access our
                  services from locations outside India, you do so at your own
                  risk and you are solely liable for compliance with applicable
                  local laws. The User agrees to use the service to authorise an
                  individual and get the products from the third party on
                  his/her behalf. Where you use any third-party website or the
                  products or services of any third party, You may be subject to
                  alternative or additional terms and conditions of use and
                  privacy policies.
                </p>
                <p>
                  The arrangement between the Third Party Service Providers, You
                  and Us shall be governed in accordance with these Terms of
                  Use.
                </p>
                <p>
                  You agree and acknowledge that the Website is a platform that
                  You and Third Party Service Providers utilize to meet and
                  interact with another for their transactions. The Company is
                  not and cannot be a party to or save as except as may be
                  provided in these Terms of Use, control in any manner, any
                  transaction between You and the Third Party Service Providers.
                </p>
                <p>
                  These Terms of Use is published in compliance of, and is
                  governed by the provisions of Indian laws, including but
                  limited to:
                </p>
                <ul>
                  <li>the Indian Contract Act, 1872;</li>
                  <li>
                    the (Indian) Information Technology Act, 2000 and the rules,
                    regulations, guidelines and clarifications framed
                    thereunder, including the (Indian) Information Technology
                    (Reasonable Security Practices and Procedures and Sensitive
                    Personal Information) Rules, 2011, and the (Indian)
                    Information Technology (Intermediaries Guidelines) Rules,
                    2011;
                  </li>
                  <li>
                    the Drugs and Cosmetic Act, 1940, read with the Drugs and
                    Cosmetics Rules, 1945;
                  </li>
                  <li>
                    the Drugs and Magic Remedies (Objectionable Advertisements)
                    Act, 1954;
                  </li>
                  <li>
                    The Indian Medical Council Act, 1956 read with the Indian
                    Medical Council Rules, 1957;
                  </li>
                  <li>Pharmacy Act, 1948; and</li>
                  <li>
                    Consumer Protection Act, 2019 and Consumer Protection
                    (E-Commerce) Rules, 2020.
                  </li>
                </ul>
                <p>&nbsp;</p>
              </div>
              <div class="terms-text">
                <h3>CREATING AN ACCOUNT</h3>
                <p>
					<ol>
                  <li>
                    To use certain features of the Website (e.g. ordering
                    Products, posting rating/reviews, receiving Pharmgo
                    e-newsletters), you must set up an account with
                    www.pharmgo.in (&ldquo;Pharmgo Account&rdquo;) and provide
                    certain information about yourself as prompted by the
                    Customer Information form, including, your name, gender,
                    email address, date of birth, account password, mobile phone
                    number and billing/shipping address. All of your
                    registration information is protected by our Privacy Policy.
                  </li>
                  <li>
                    You represent and warrant that the information you submit
                    during registration is truthful and accurate and that you
                    will maintain the accuracy of such information.
                  </li>
                  <li>
                    Your Pharmgo Account information are personal to you. You
                    may not transfer your account and you will be responsible
                    for the activities associated with your Account.
                  </li>
                  <li>
                    You may be required to providing information including but
                    not limited to Your full Name, Complete postal address,
                    Gender, Age, Date of birth, Mobile number, Email Id, Valid
                    prescription, Patient details, Financial account information
                    etc. in order to facilitate the use of Your Account.
                  </li>
                  <li>
                    www.pharmgo.in will not be liable for any loss or damages as
                    a result of your failure to maintain the confidentiality of
                    your account credentials. If you suspect any unauthorized
                    use of your Wellness Forever account, you shall immediately
                    notify at
                    <b>support@pharmgo.in</b>.
                  </li>
				  </ol>
                </p>

				<p>
      1.5 It is your responsibility to keep your email address up-to-date on
      your account setup at www.pharmgo.in so that we can communicate with you
      electronically.
    </p>
    <p>&nbsp;</p>
              </div>
              <div class="terms-text">
                <h3>ELIGIBILITY</h3>
                <p>
      When you use the Website, you represent that you meet the following
      primary eligibility criteria:
    </p>
    <ol>
      <li>
        You are at least 18 years old or accessing the Website under the
        supervision of a parent or guardian, who in such a case will be deemed
        as the recipient / end-user of the Services (as defined in these Terms
        and Conditions) for the purpose of these Terms and Conditions.
      </li>
      <li>
        If your age is below that of 18 years, your parents or legal guardians
        can transact on behalf of you if they are registered users. You are
        prohibited from purchasing any material the sale or purchase of which
        to/by minors is prohibited and which is for consumption by adults only.
      </li>
      <li>
        You are legally competent to contract, and otherwise competent to
        receive the Services (as defined in these Terms and Conditions). Persons
        who are "incompetent to contract" within the meaning of the Indian
        Contract Act, 1872 including un-discharged insolvents etc. are not
        eligible to use the Website.
      </li>
      <li>
        You have not been previously suspended or removed by CentDeal or
        disqualified for any other reason, from availing the Services.
      </li>
      <li>
        The Company reserves the right to terminate your membership and/or
        refuse to provide you with access to the Website if We discover that you
        are under the age of 18 years.
      </li>
    </ol>
    <p>&nbsp;</p>
              </div>

			  <div class="terms-text">
                <h3>DISCLAIMER OF WARRANTIES AND LIABILITIES</h3>
				<p>
      You expressly understand and agree that, to the maximum extent permitted
      by applicable law the Website, services and other materials are provided
      by Company on an "as is" basis without warranty of any kind, express,
      implied, statutory or otherwise, including the implied warranties of
      title, non-infringement, merchantability or fitness for a particular
      purpose. Without limiting the foregoing, Company makes no warranty that
      (i) the Website or the services will meet your requirements or your use of
      the Website or the services will be uninterrupted, timely, secure or
      error-free; (ii) the results that may be obtained from the use of the
      Website, services or materials will be effective, accurate or reliable;
      (iii) the quality of the Website, services or other materials will meet
      your expectations; or that (iv) any errors or defects in the Website,
      services or other materials will be corrected. No advice or information,
      whether oral or written, obtained by you from Company or through or from
      use of the services shall create any warranty not expressly stated in the
      Terms.
    </p>
    <p>
      To the maximum extent permitted by applicable law, Company will have no
      liability related to user content arising under intellectual property
      rights, libel, privacy, publicity, obscenity or other laws. Company also
      disclaims all liability with respect to the misuse, loss, modification or
      unavailability of any user content. Please note that while We have made
      every effort to accurately display the colours of products on its website,
      the actual colour you see will depend on your monitor. We cannot guarantee
      that your monitor&rsquo;s display of any colour will be the same as the
      colour of the products delivered to you
    </p>
    <p>
      Company will not be liable for any loss that You may incur as a
      consequence of unauthorized use of Your Account or Account Information in
      connection with the Website or any services or materials, either with or
      without your knowledge. Company has endeavoured to ensure that all the
      information on the Website is correct, but Company neither warrants nor
      makes any representations regarding the quality, accuracy or completeness
      of any data, information, product or service. Company shall not be
      responsible for the delay or inability to use the Website or related
      functionalities, the provision of or failure to provide functionalities,
      or for any information, software, products, functionalities and related
      graphics obtained through the Website, or otherwise arising out of the use
      of the Website, whether based on contract, tort, negligence, strict
      liability or otherwise. Further, Company shall not be held responsible for
      non-availability of the Website during periodic maintenance operations or
      any unplanned suspension of access to the Website that may occur due to
      technical reasons or for any reason beyond Company's control. The User
      understands and agrees that any material or data downloaded or otherwise
      obtained through the Website, is done entirely at their own discretion and
      risk and they will be solely responsible for any damage to their computer
      systems or loss of data that results from the download of such material or
      data. Company is not responsible for any typographical error leading to an
      invalid coupon. Company accepts no liability for any errors or omissions,
      with respect to any information provided to you whether on behalf of
      itself or third parties.<br />
      Company shall not be liable for any third-party product or services. You
      expressly agree that Your use of the Website is at Your risk.
    </p>
    <p>
      This Website and the services are provided to you on an &ldquo;as
      is&rdquo; and &ldquo;where-is&rdquo; basis, without any representations or
      warranties. we, for our self and any third-party providing materials,
      services, or content to this website, make no representations or
      warranties, either express, implied, statutory or otherwise of
      merchantability, fitness for a particular purpose, or non-infringement of
      third-party rights, with respect to the platform, the information or any
      products or services to which the information refers. we will not be
      liable to you or any third party for any damages of any kind, including
      but not limited to, direct, indirect, incidental, consequential or
      punitive damages, arising from or connected with the site, including but
      not limited to, your use of this site or your inability to use the site,
      even if we have previously been advised of the possibility of such
      damages.
    </p>
    <p>
      Disclaimer- To the extent permitted by applicable law, We, our officers,
      agents, employees and directors (&ldquo;Protected Entities&rdquo;),
      disclaim any liability against any loss, damage, expenses, liabilities,
      claim, injury caused due to the failure of performance, omission, defect
      of products, or deletion, interruption, error, delay, virus,
      communication, unauthorised access, theft, destruction, alteration or use
      of records on the Website.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>PRICE AND PAYMENT</h3>
				
			<ol>
					<li>
        All our product prices include all applicable statutory taxes, fees and
        subject to availability.
      </li>
      <li>
        We make every effort to make sure that the pricing and availability of
        products on our website is accurate and up to date. However, rarely,
        there may be an error on the pricing of a product or an error related to
        product availability. In such cases, we are not responsible for any
        typographical errors and we reserve the right to cancel the sale.
      </li>
      <li>
        We reserve the right to correct any inaccuracies or omissions related to
        pricing and product availability/descriptions, even after you have
        submitted your order, and to change or update any other information at
        any time without prior notice.
      </li>
      <li>
        We reserve the right, without prior notice, to limit the order quantity
        of any products available on the Website.
      </li>
      <li>
        Online Payments are processed via our online payment gateway partners.
      </li>
    </ol>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>ADVERTISEMENTS</h3>
				<p>
      As part of the Services provided by Us; We may facilitate and allow third
      party advertisers (&ldquo;Third Party Advertisers&rdquo;) to place
      advertisements on the Website.
    </p>
    <p>
      The Company may, at any time and without having to serve any prior notice
      to the Third Party Advertisers, (i) upgrade, update, change, modify, or
      improve the Website or a part thereof in a manner it may deem fit, and
      (ii) change the content of the Advertising Policy and/ or these Terms and/
      or the Privacy Policy. It is the responsibility of the Third Party
      Advertisers, in such cases, to review the terms of the Advertising Policy
      and/ or these Terms and/ or the Privacy Policy, from time to time. Such
      change shall be made applicable when they are posted. We may also alter or
      remove any content from the Website without notice and without liability.
      The Third Party Advertisers are also responsible for ensuring that their
      advertisements comply with all applicable law(s) in India and any other
      jurisdiction that such Third Party Advertiser(s) are based out of,
      industry codes, rules and regulations in each geographic area where the
      advertisements will run. All disclosures in the advertisements must be
      clear and conspicuous. We are not responsible for any liability arising
      from an advertisement by a Third Party Advertiser.
    </p>
    <ul>
      <li>
        <u>Prohibited Advertisements</u>: Advertisements that come under the
        definition of prohibited advertisement include but are not limited to
        adult products and services; cigarettes and other tobacco products;
        products or services that bypass copyright protection; pyramid schemes
        or offers or any other deceptive or fraudulent offers; selling
        counterfeit goods or engaging in copyright piracy; illegal or
        recreational drugs; bootleg products, or replicas or imitations of
        designer products; firearms, weapons, ammunition, or accessories;
        illegal conduct, product, or enterprise; prescription or unapproved
        drugs; material that directly advertises products to or is intended to
        attract children under the age of 13.
      </li>
    </ul>
    <p>&nbsp;</p>
    <p>
      It is hereby clarified that that the Third Party Advertisers will also
      comply with all the provisions of the Drugs and Magic Act and the rules
      made thereunder. Further, it is agreed that the Third Party Advertisers
      shall be solely responsible for any penalty or any action taken by the
      governmental authorities for non-compliance with the Drugs and Magic Act
      and the rules made thereunder.
    </p>
    <p>&nbsp;</p>
    <ul>
      <li>
        <u>Restricted Advertisements:</u> Advertisements in the categories that
        are restricted and require approval on a case-to-case basis that include
        but are not limited to advertisements that promote or reference alcohol;
        online dating services; financial services; gambling and games of skill;
        lotteries; contraceptives; online pharmacies or pharmaceuticals; and
        political advertisements.
      </li>
    </ul>
    <p>&nbsp;</p>
    <ul>
      <li>
        <u>Testimonials &amp; Endorsements</u>: Any testimonials and
        endorsements contained in advertisements must comply with all applicable
        law(s), industry codes, rules, and regulations.
      </li>
    </ul>
    <p>
      The Website recognizes and maintains a distinct separation between
      advertising and sponsored content and editorial content. All advertising
      or sponsored content on the Website of the Company will be clearly and
      unambiguously identified. Clicking on an advertisement may only link the
      User to the website of the Third Party Advertiser(s).
    </p>
    <p>
      The Company reserves the right to reject or remove any advertisement in
      its sole discretion for any reason. Further, the Company also reserves the
      right to request modifications to any advertisement, and to require
      factual substantiation for any claim made in an advertisement.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>USAGE CONDUCT</h3>
				<p>
      As a User and recipient of Services, when you use the Website, you agree
      to the following conditions of use:
    </p>
    <ol>
      <li>
        You are solely responsible for the medical, health and personal
        information you provide on the Website and you are requested to use your
        discretion in providing such information.
      </li>
      <li>
        You will provide accurate and complete information everywhere on the
        Website, based on which you will receive the Services.
      </li>
      <li>
        You will be solely responsible for all access to and use of this Website
        by anyone using the password and identification originally assigned to
        you whether or not such access to and use of this Website is actually
        authorized by you, including without limitation, all communications and
        transmissions and all incurred through such access or use. You are
        solely responsible for protecting the security and confidentiality of
        the password and identification assigned to you.
      </li>
      <li>Your right to use Our Services is not transferable.</li>
      <li>
        The Website is for personal use and the Services are for individuals to
        use for supporting their personal health decisions. You may use the
        Website for personal, but not for commercial, purposes.
      </li>
      <li>
        &nbsp;The Website may not be used for illegal purposes. The Information
        and Services may not be used for any illegal purpose. You may not access
        our networks, computers, or the Information and Services in any manner
        that could damage, disable, overburden, or impair them, or interfere
        with any other person's use and enjoyment. You may not attempt to gain
        unauthorized access to any Information or Services, other accounts,
        computer systems, or networks connected with the Website, the
        Information, or Services. You may not use any automated means (such as a
        scraper) to access the Website, the Information, or Services for any
        purpose. Such unauthorized access includes, but is not limited to, using
        another person&rsquo;s login credentials to access his or her profile/
        account. Any attempt by any individual or entity to solicit login
        information of any other user or to access any such account is an
        express and direct violation of these Terms of Use and of applicable
        law(s), including relevant privacy and security laws and laws
        prohibiting unfair or unethical business practices.
      </li>
      <li>
        The information provided by you may be used by us for the purpose of
        Services including analysis, research, training and disclosure (where
        required) to our affiliates, group companies, vendors, agents and
        government authorities, etc. as stated in our Privacy Policy.
      </li>
    </ol>
    <p><strong>&nbsp;</strong></p>
			  </div>

			  <div class="terms-text">
                <h3>SCOPE OF SERVICES</h3>
				<p>
      The Company engages in retail sale to end customers through both online
      channels, i.e. through its Website. The Website will display the inventory
      of products available only in your region, based on the pin code entered
      by you on the Website.
    </p>
    <p>
      Inter-state delivery of medicines and other products is fulfilled through
      external delivery partners. The Company may also engage external delivery
      partners for certain inter-city and local deliveries. Any delay, loss,
      harm, theft or damage arising from the shipping of products by the
      external delivery partner is the sole liability of the external delivery
      partner. The Company shall not be held liable for the actions of the
      external delivery partner in any way whatsoever.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>LIMITATION OF LIABILITY</h3>
				<p>
      By using our Services, you confirm that you understand and agree to the
      following:
    </p>
    <ol>
      <li>
        To the extent permitted by applicable law, the Company or its affiliates
        will not be liable to you for any special, indirect, incidental,
        consequential, punitive, reliance, or exemplary damages arising out of
        or relating to:
      </li>
    </ol>
    <p>(i) these Terms and Conditions and Privacy Policy;</p>
    <p>(ii) your use or inability to use the Website;</p>
    <p>
      (iii) your use of any third party services you contacted through the
      Website.
    </p>
    <ol>
      <li>
        The Company does not warrant that the Website or any of the Services or
        Products available through it will be uninterrupted or free from errors.
        There may be delay, omissions, interruption, and/or inaccuracies in
        materials or Service available through the Website.
      </li>
      <li>
        Although the Company takes reasonable steps to prevent the introduction
        of viruses, worms or other malicious code to the Website, We do not
        represent or warrant that the Website, or the Service or Products or
        materials that may be made available through the Website are free from
        such destructive features. The Company is not liable for any damages or
        harm attributable to such features or arising directly or indirectly
        from such features.
      </li>
      <li>
        To the extent permitted by law, the Company shall not be liable to you
        or to any third party for any direct, incidental, indirect, special or
        consequential losses, damages whatsoever (including, but not limited to,
        lost profits, business interruption, loss of programs or other data on
        your information system), even if We have been advised, knew, or should
        have known of the possibility of such damages, arising out of or related
        to:
      </li>
    </ol>
    <p>
      (i) your use of or reliance on the Website, any information, hyperlinks or
      content contained therein, or Services included on or otherwise made
      available to you through the Website;
    </p>
    <p>(ii) your provision of information, personal or otherwise, to Us;</p>
    <p>(iii) the provision of Services by Us; or</p>
    <p>
      (iv) acts or negligence on the part of the Company, its agents or
      employees;
    </p>
    <p>
      (v) errors, delays or deficiencies in services or theft or damage of
      Products, attributable to third parties who assist Us such as delivery
      partners.
    </p>
    <ol>
      <li>
        To the fullest extent permitted by law, the Company disclaims and
        excludes all warranties and representations, express, implied or
        statutory, with respect to the Website or its services, or with respect
        to the accuracy, currency or completeness of the information provided by
        Us, including the implied warranties of merchantability or fitness for a
        particular purpose and non-infringement of a patent, trademark or other
        intellectual property right. The Website, including, without limitation,
        all content, information and links contained therein, is provided
        &ldquo;as-is&rdquo; without any warranty that it will be uninterrupted
        or error free. You expressly agree that your use of this Website is at
        your sole risk.
      </li>
      <li>
        Notwithstanding anything herein to the contrary, the Company&rsquo;s
        aggregate liability (whether in contract, tort or otherwise) for any
        loss or damage that you may incur on any account whatsoever, arising out
        of your use of the Website, shall be limited to a sum equal to the
        amount paid or payable by you for the Product(s) or Services in respect
        of one incident or series of incidents attributable to the same cause.
      </li>
      <li>
        The Company reserves the right to refuse Service, terminate accounts,
        remove or edit content, or cancel orders anytime at their sole
        discretion.
      </li>
      <li>
        We have selected the Products on the basis that they will be used for
        personal use only. If you are planning to use them for business purposes
        you are advised to make sure that you are covered by a proper insurance
        plan. Where you decide to use the Products in the course of a business,
        we exclude (to the fullest extent permitted by law) those warranties and
        conditions relating to fitness for a particular purpose. Our maximum
        liability to business users arising out of or in connection with the
        Products shall be limited to the replacement value of the Product in
        question. In relation to business users, we do not accept liability for
        the fitness of Products for business purposes, nor do we accept
        liability for loss of use of the Product, nor any loss over and above
        the cost of the Products in the event of a claim for breach of warranty
        or condition.
      </li>
      <li>
        The Company (including but not limited to the owners and the employee
        staff) and third-party professionals who offer the Services through the
        Website accept no responsibility for any medical, legal or financial
        events or outcomes related to the Services availed through the use of
        the Website.
      </li>
      <li>
        The Company makes no warranty that the Services will meet Your
        requirements, or that the Service(s) will be uninterrupted, timely,
        secure, or error free. This includes loss of data or any service
        interruption caused by our Company&rsquo;s employees. The Company is not
        responsible for transmission errors, corruption of data.
      </li>
      <li>
        At no time shall Company hold any right, title or interest over the
        products, nor shall Company have any obligations or liabilities in
        respect of such contract entered into between Users and third party
        service providers. Company is not responsible for any unsatisfactory or
        delayed performance of services or damages or delays as a result of
        products which are out of stock, unavailable or back ordered. The
        limitations and exclusions in this section apply to the maximum extent
        permitted by applicable law.
      </li>
    </ol>
    <p>
      This section shall survive the termination of this Agreement and the
      termination of your use of our Services or the Website.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>COVENANTS</h3>
				<p>
      Covenanters for the purposes of these Terms of Use shall include the Users
      and the Third Party Service Providers.
    </p>
    <p>
      Each Covenanter undertakes that it shall not do any act or post, display,
      upload, modify, publish, transmit, update or share any information that:
    </p>
    <ol>
      <li>
        belongs to another person and to which such Covenanter does not have any
        right;
      </li>
      <li>
        is grossly harmful, insulting or harassing on the basis of gender,
        blasphemous, defamatory, obscene, pornographic, paedophilic, libellous,
        invasive of another's privacy including bodily privacy, hateful,
        racially or ethnically objectionable, disparaging, relating or
        encouraging money laundering or gambling, or otherwise inconsistent with
        or contrary to the laws in force;
      </li>
      <li>violates any law for the time being in force;</li>
      <li>impersonates another person;</li>
      <li>is harmful to child;</li>
      <li>
        deceives or misleads the addressee about the origin of such messages or
        knowingly and intentionally communicates any information that is
        patently false or misleading in nature but may reasonably be perceived
        as a fact;
      </li>
      <li>
        contains software viruses or any other computer code, files or programs
        designed to interrupt, destroy or limit the functionality of any
        computer resource;
      </li>
      <li>
        is prohibited under applicable law(s) for the time being in force
        including Drugs Act read with the Drugs Rules, the Drugs and Magic Act,
        the Indian Penal Code, 1860, as amended from time to time and rules made
        there under;
      </li>
      <li>
        is patently false and untrue, and is written or published in any form,
        with the intent to mislead or harass a person, entity or agency for
        financial gain or to cause any injury to any person; and
      </li>
      <li>
        threatens the unity, integrity, defence, security or sovereignty of
        India, friendly relations with foreign states, or public order or causes
        incitement to the commission of any cognizable offence or prevents
        investigation of any offence or is insulting any other nation.
      </li>
    </ol>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>USE OF SERVICES AND THE WEBSITE</h3>
				<li><u>Platform to facilitate transaction of business</u>:</li>
				<p>
      Through the Website, the Company facilitates the purchase of drugs and
      other pharmaceutical products, and services offered for sale by Third
      Party Pharmacies (&ldquo;Pharmaceutical Goods and Services&rdquo;). You
      understand and agree that the Company and the Website merely provide
      hosting services to You and persons browsing / visiting the Website. All
      items offered for sale on the Website, and the content made available by
      the Third Party Pharmacies, are third party user generated contents and
      third party products. The Company has no control over such third party
      user generated contents and/ Pharmaceutical Goods and Services and does
      not - originate or initiate the transmission, or select the
      sender/recipient of the transmission, or the information contained in such
      transmission. The authenticity and genuineness of the Pharmaceutical Goods
      and Services made available by the Third Party Pharmacies through the
      Website shall be the sole responsibility of the Third Party Pharmacies.
      You understand and agree that We shall have no liability with respect to
      the authenticity of the Pharmaceutical Goods and Services being
      facilitated through the Website.
    </p>
    <p>
      &nbsp;You understand and agree that all commercial / contractual terms,
      with respect to the sale/ purchase/ delivery and consumption of the
      Pharmaceutical Goods and Services which are offered by and agreed to
      between You and the Third Party Pharmacies and the contract for purchase
      of any of the Pharmaceutical Goods and Services, which are offered for
      sale on the Website by the Third Party Pharmacies shall strictly be a
      bipartite contract between the Third Party Pharmacies and You.
    </p>
    <p>
      &nbsp;The commercial / contractual terms include without limitation -
      price, shipping costs, payment methods, payment terms, date, period and
      mode of delivery, warranties related to Pharmaceutical Goods and Services
      offered for sale by the Third Party Pharmacies, and after sales services
      related to such Pharmaceutical Goods and Services are as provided by the
      Third Party Pharmacies. The Company does not have any control over, and
      does not determine or advise or in any way involve itself in the offering
      or acceptance of, such commercial / contractual terms offered by and
      agreed to, between You and the Third Party Pharmacies.
    </p>
    <ol>
      <li><u>Representation as to legal title</u>:</li>
    </ol>
    <p>
      The Company does not make any representation or warranty as to legal title
      of the Pharmaceutical Goods and Services offered for sale by the Third
      Party Pharmacies on the Website.
    </p>
    <p>
      &nbsp;At no time shall any right, title, claim or interest in the products
      sold through or displayed on the Website vest with the Company nor shall
      We have any obligations or liabilities in respect of any transactions on
      the Website. You agree and acknowledge that the ownership of the inventory
      of such Pharmaceutical Goods and Services shall always vest with the Third
      Party Pharmacies, who are advertising or offering them for sale on the
      Website and are the ultimate sellers.
    </p>
    <p>
      &nbsp;You agree and acknowledge that the Third Party Pharmacies shall be
      solely responsible for any claim/ liability/ damages that may arise in the
      event it is discovered that such Third Party Pharmacies do not have the
      sole and exclusive legal ownership over the Pharmaceutical Goods and
      Services that have been offered for sale on the Website by such Third
      Party Pharmacies, or did not have the absolute right, title and authority
      to deal in and offer for sale such Pharmaceutical Goods and Services on
      the Website.
    </p>
    <ol>
      <li><u>Non-Performance of Contract</u>:</li>
    </ol>
    <p>You accept and acknowledge the following:</p>
    <p>
      &nbsp;The Company is not responsible for any unsatisfactory, delayed,
      non-performance or breach of the contract entered into between You and the
      Third Party Pharmacies for purchase and sale of goods or services offered
      by such Third Party Pharmacies on the Website;
    </p>
    <p>
      &nbsp;The Company cannot and does not guarantee that the concerned Third
      Party Pharmacies will perform any transaction concluded on the Website;
    </p>
    <p>
      &nbsp;The Third Party Pharmacy(s) are solely responsible for ensuring that
      the Pharmaceutical Goods and Services offered for sale on the Website are
      kept in stock for successful fulfilment of orders received. Consequently,
      We are not responsible if the Third Party Pharmacy(s) does not satisfy the
      contract for sale of Pharmaceutical Goods and Services which are out of
      stock, back ordered or otherwise unavailable, but were shown as available
      on the Website at the time of placement of order by You; and
    </p>
    <p>
      &nbsp;The Company shall not and is not required to mediate or resolve any
      dispute or disagreement between You and Third Party Pharmacies. In
      particular, We do not implicitly or explicitly support or endorse the sale
      or purchase of any items or services on the Website. We shall, on a
      request in writing made by You after the purchase of any Pharmaceutical
      Goods and Services on the Website, provide You with information regarding
      the Third Party Pharmacies from which You have made such purchase,
      including the principal geographic address of its headquarters and all
      branches, name and details of its website, its email address and any other
      information necessary for communication with the Third Party Pharmacy for
      dispute resolution.
    </p>
    <ol>
      <li>
        <u
          >Exhibition of drugs and publication of Third Party Pharmacies content
          on the Website</u
        >:
      </li>
    </ol>
    <p>
      &nbsp;You agree and acknowledge that the respective Third Party Pharmacies
      are exhibiting Third Party Content which includes catalogue of drugs/
      pharmaceutical products or services, and information in relation to such
      drugs/ pharmaceutical products or services, on the Website.
    </p>
    <p>
      &nbsp;The Third Party Content available on the Website, including without
      limitation, text, copy, audio, video, photographs, illustrations, graphics
      and other visuals, is for general information purposes only and does not
      constitute either an advertisement/ promotion of any drug being offered
      for sale by the Third Party Pharmacies on the Website or any professional
      medical advice, diagnosis, treatment or recommendations of any kind.
    </p>
    <p>
      You acknowledge and agree that such Third Party Pharmacies shall be solely
      responsible for ensuring that such Third Party Content made available
      regarding the Pharmaceutical Goods and Services offered for sale on the
      Website, are not misleading and describe the actual condition of the
      Pharmaceutical Goods and Services. In this connection, it is solely the
      responsibility of the concerned Third Party Pharmacy(s) to ensure that all
      such information is accurate in all respects and there is no exaggeration
      or over emphasis on the specifics of such Pharmaceutical Goods and
      Services so as to mislead the Users in any manner. You acknowledge and
      understand that We provide no warranty or representation with respect to
      the authenticity/ veracity of the information provided on the Website and
      You must run Your own independent check. You agree and acknowledge that We
      have not played any role in the ascertainment of the actual impact/ effect
      of any Pharmaceutical Goods and Services being offered for sale by the
      Third Party Pharmacies on the Website. Further, it is hereby clarified
      that the Third Party Pharmacies are offering the Pharmaceutical Goods and
      Services for sale to You and they are responsible for procuring the
      appropriate licenses for the same under the Drugs Act read with the Drug
      rules and the Pharmacy Act. You agree and acknowledge that You shall not
      hold the Company responsible or liable for any damages arising out of such
      reliance on third party user generated content by You.
    </p>
    <p>
      The Website is a platform that can be used by the Users to purchase
      various drugs and pharmaceutical products that requires a valid medical
      prescription issued by a medical expert/ doctor to be provided to a
      registered pharmacist for the purpose of dispensing such medicine
      (&ldquo;Prescription Drugs&rdquo;), offered for sale on the Website by
      Third Party Pharmacies. In order to purchase Prescription Drugs from Third
      Party Pharmacies through the Website, You are required to upload a scanned
      copy of the valid prescription on your Account or email the same at
      <b>support@pharmgo.in</b>. The order would not be processed and forwarded
      to the concerned Third Party Pharmacy(s) by Our Company until it receives
      a copy of a valid prescription. Third Party Pharmacies will verify the
      prescription forwarded by You and in case of Third Party Pharmacy(s)
      observe any discrepancy in the prescription uploaded by You, the Third
      Party Pharmacy(s) will cancel the order immediately. You are also required
      to make the original prescription available at the time of receipt of
      delivery of Prescription Drugs. You shall allow the delivery agent to
      stamp the original prescription at the time of medicine delivery failing
      which medicines will not be delivered.
    </p>
    <p>
      You acknowledge and accept that the order for a substitute of a
      Prescription Drug would only be processed if the medical expert/ doctor
      has himself/ herself permitted for any other equivalent generic drug to be
      dispensed in place of the Prescription Drug in the prescription or if the
      prescription solely lists the salt names instead of a specific brand name.
    </p>
    <p>
      You accept that, in the absence of the above, the concerned Third Party
      Pharmacy would not dispense a substitute drug in place of the Prescription
      Drug.
    </p>
    <p>
      We shall maintain a record of all the prescriptions uploaded by the Users.
    </p>
    <p>&nbsp;</p>

			  </div>

			  <div class="terms-text">
                <h3>NOTICE AND TAKEDOWN OF CONTENT</h3>
				<p>
      If You believe that any Content on the Website is defamatory, obscene,
      pornographic, paedophilic, illegal, offensive, sexually explicit, promotes
      bigotry, hatred or physical harm, deceptive, misleading, abusive,
      indecent, harassing, blasphemous, defamatory, libellous, invasive of
      another&rsquo;s privacy, including bodily privacy, insulting or harassing
      on the basis of gender, racially or ethnically objectionable, relating or
      encouraging money laundering or gambling; or is otherwise injurious to
      third parties; or is harmful to minors in any way; or impersonates another
      person; or threatens the unity, integrity, security or sovereignty of any
      nation or its or friendly relations with foreign States; or which consists
      of or contains software viruses or violates any of the Terms, please
      notify Us immediately at our registered email id
      <b>support@pharmgo.in</b> . As soon as you inform Us, We will make all
      reasonable endeavours to remove such objectionable content complained
      about within a reasonable time.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>VIOLATION OF TERMS</h3>
				<p>
      You agree that Company may, in its sole discretion and without prior
      notice, terminate Your access to the Website and block Your future access
      to the Website, if Company determines that You have violated these Terms
      or Additional Terms. You also agree that any violation by You of these
      Terms will constitute an unlawful and unfair business practice, and will
      cause irreparable harm to Company, for which monetary damages would be
      inadequate, and You consent to Company obtaining any injunctive or
      equitable relief that Company deems necessary or appropriate in such
      circumstances. These remedies are in addition to any other remedies,
      Company may have at law or in equity. You agree that Company may, in its
      sole discretion, and without prior notice, terminate Your access to the
      Website, for cause, which includes (but is not limited to): (1) requests
      by law enforcement or other government agencies; (2) a request by You
      (self-initiated account deletions); (3) discontinuance or material
      modification of the Website or any service offered on or through the
      Website; or unexpected technical issues or problems. If Company does take
      any legal action against You as a result of Your violation of these Terms,
      Company will be entitled to recover from You, and You agree to pay, all
      reasonable attorneys' fees and costs of such action, in addition to any
      other relief granted to Company.
    </p>
    <p><strong>&nbsp;</strong></p>
			  </div>

			  <div class="terms-text">
                <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
				<p>
      The Website, the processes, and their selection and arrangement, including
      but not limited to all text, graphics, user interfaces, visual interfaces,
      sounds and music (if any), artwork and computer code (collectively, the
      "Content") on the Website is owned and controlled by Company and the
      design, structure, selection, coordination, expression, look and feel and
      arrangement of such Content is protected by copyright, patent and
      trademark laws, and various other intellectual property rights. The
      trademarks, logos and service marks displayed on the Website ("Marks") are
      the property of Company or their third party partners or respective third
      parties. You are not permitted to use the Marks without the prior consent
      of Company and/or the relevant third parties that may own the Marks.
      Unless otherwise indicated or anything contained to the contrary or any
      proprietary material owned by a third party and so expressly mentioned,
      Company owns all intellectual property rights in and to the trademark
      &ldquo;pharmgo&rdquo;, domain name &ldquo;www.pharmgo.in&rdquo;, and the
      Website, including, without limitation, any and all rights, title and
      interest in and to copyright, related rights, patents, utility models,
      designs, know-how, trade secrets and inventions (patent pending),
      goodwill, source code, meta tags, databases, text, content, graphics,
      icons, and hyperlinks. Except as expressly provided herein, You
      acknowledge and agree that You shall not copy, republish, post, display,
      translate, transmit, reproduce or distribute any Content through any
      medium without obtaining the necessary authorization from Company or third
      party owner of such Content.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>INDEMNITY</h3>
				<p>
      You agree to indemnify, defend and hold harmless Company including but not
      limited to independent contractors, service providers, licensors, agents,
      and representatives and each of its affiliates, vendors, representatives,
      directors, agents and employees from and against any and all losses,
      liabilities, claims, damages, demands, costs and expenses (including legal
      fees and disbursements in connection therewith and interest chargeable
      thereon) asserted against or incurred by Company that arise out of, result
      from, or may be payable by virtue of, any breach or non-performance or any
      such conduct of any representation, warranty, covenant or agreement made
      or obligation to be performed by You pursuant to these Terms. Further, You
      agree to hold Company harmless against any claims made by any third party
      due to, or arising out of, or in connection with, Your use of the Website,
      any claim that Your material caused damage to a third party, Your
      violation of the Terms, or Your violation of any rights of another,
      including any intellectual property rights.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>THIRD PARTY LINKS</h3>
                <p>
      Where the Website contains links to other sites and resources provided by
      third parties (including where our social media sharing plug-ins include
      links to third party sites), these links are provided for your information
      only/ merely as a convenience. We have no control over the contents of
      those websites or resources and accept no responsibility for them or for
      any loss or damage that may arise from your use of them. Any links to such
      linked Internet sites should no way be construed as an endorsement,
      representation or promotion by the Company as to the content,
      representation, accuracy, products or services found or otherwise
      described in such linked Internet sites. Use of those linked Internet
      sites/links is done at your own risk and cost. You will be bound by the
      privacy policy and terms of use of the respective website when you
      navigate away from the Website to any such other website.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>RETURN, REFUND, CANCELLATION AND SHIPPING CHARGES:</h3>
                <p>
      We offer return and refund on the products and Services ordered by You on
      the Website which are subject to further terms and conditions as detailed
      in the cancellation, shipping charges, returns and refund policy
      (&ldquo;Shipping, Return, Cancellation And Refund Policy&rdquo;). The
      Return and Refund Policy forms an integral part of these Terms and the
      Users are requested to carefully read the same.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>DATA &amp; INFORMATION POLICY</h3>
                <p>
      We respect your right to privacy in respect of any personal information
      provided to us for the purposes of availing our Services. To see how we
      collect and use your personal information, please see our&nbsp;Privacy
      Policy.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>OTHER TERMS OF USE</h3>
          <p>
      Your Profile, Collection, Use, Storage and Transfer of Personal
      Information:
    </p>
    <p>
      Your profile is created to store record of Your personal health
      information online, including history, health conditions, allergies and
      medications. Any information provided as part obtained from use of the
      Services by You becomes part of Your record. You agree to provide accurate
      information to help Us serve You best to Our knowledge, to periodically
      review such information and to update such information as and when
      necessary. Our Company reserves the right to maintain, delete or destroy
      all communications and materials posted or uploaded to the Website
      according to its internal record retention and/or destruction policies.
      You might be contacted via email to review the information provided by You
      for Our Company&rsquo;s record or for the Services. Please make sure You
      provide a valid email-id and You update it as and when needed.
    </p>
    <p>
      For additional information regarding use of information about You, please
      refer to the Privacy Policy.
    </p>
    <p>
      &nbsp;The terms &ldquo;personal information&rdquo; and &ldquo;sensitive
      personal data or information&rdquo; are defined under the Information
      Technology (Reasonable Security Practices and Procedures and Sensitive
      Personal Information) Rules, 2011 (the &ldquo;SPI Rules&rdquo;), and are
      reproduced in the Privacy Policy.
    </p>
    <p>
      &nbsp;The Privacy Policy sets out the type of information collected from
      Users, including sensitive personal data or information; purpose, means
      and modes of usage of such information; and how and to whom We will
      disclose such information.
    </p>
    <p>
      The Users are expected to read and understand the Privacy Policy, so as to
      ensure that he or she has the knowledge of the fact that the information
      is being collected; purpose for which the information is being collected;
      intended recipients of the information; name and address of the agency
      that is collecting the information and the agency that will retain the
      information; and various rights available to such Users in respect of such
      information. Our Company shall not be responsible in any manner for the
      authenticity of the personal information or sensitive personal data or
      information supplied by the Users to CentDeal or any other person acting
      on behalf of the Company.
    </p>
    <p>
      The use of the Website involves every Users&rsquo; registration
      information and browsing history being stored and submitted to the
      appropriate authorities. The consent and procedure for such collection and
      submission is provided in the Privacy Policy. The other information
      collected by the Website from Users as part of the registration process is
      described in the Privacy Policy. The consent and revocation procedures in
      relation to the same are set out in the Privacy Policy.
    </p>
    <p>
      &nbsp;The Users are responsible for maintaining the confidentiality of the
      Users&rsquo; account access information. The Users shall immediately
      notify CentDeal of any actual or suspected unauthorized use of the
      Users&rsquo; account or password.
    </p>
    <p>
      &nbsp;If a User provides any information that is untrue, inaccurate, not
      current or incomplete (or becomes untrue, inaccurate, not current or
      incomplete), or CentDeal has reasonable grounds to suspect that such
      information is untrue, inaccurate, not current or incomplete, We shall
      have the right to suspend or terminate such account at its sole
      discretion.
    </p>
    <p>
      Our Company may disclose or transfer User Information (as defined in the
      Privacy Policy) to its affiliates in other countries, and You hereby
      consent to such transfer. The SPI Rules only permit Our Company to
      transfer sensitive personal data or information including any information,
      to any other body corporate or a person in India, or located in any other
      country, that ensures the same level of data protection that is adhered to
      by Our Company as provided for under the SPI Rules, only if such transfer
      is necessary for the performance of the lawful contract between CentDeal
      or any person on its behalf and the user or where the User has consented
      to data transfer.
    </p>
    <p>
      By accepting these Terms of Use and by registering on the Website, You
      consent to be contacted by Us and/or by our third party service providers.
      You further consent to receive Calls, emails and messages (SMS)
      notifications and information from Us and from Third Party Service
      Providers including for promotions, discount and/or other service delivery
      related issues.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>MODIFICATIONS / AMENDMENTS</h3>
            <p>
      We may from time to time update or revise these Terms and Conditions.
      Every time you wish to use the Website, please check the relevant Terms
      and Conditions and Privacy Policy to ensure you understand the terms that
      apply at that time.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>EVENTS BEYOND OUR CONTROL</h3>
            <p>
      We will not be liable for any non-compliance or delay in compliance with
      any of the obligations we assume under any contract including any delay or
      failure to deliver Products when caused by events that are beyond our
      reasonable control (<strong>&ldquo;Force Majeure&rdquo;</strong>). Force
      Majeure shall include any act, event, failure to exercise, omission or
      accident that is beyond our reasonable control, including, among others,
      the following:
    </p>
    <p>&nbsp;</p>
    <ol>
      <li>Strike, lockout or other forms of protest;</li>
      <li>
        Civil unrest, revolt, invasion, terrorist attack or terrorist threat,
        war (declared or not) or threat or preparation for war;
      </li>
      <li>
        Fire, explosion, storm, flood, earthquake, collapse, epidemic or any
        other natural disaster;
      </li>
      <li>
        Inability to use public or private transportation and telecommunication
        systems.
      </li>
      <li>
        Acts, decrees, legislation, regulations or restrictions of any
        government or public authority including any judicial determination.
      </li>
    </ol>
    <p>&nbsp;</p>
    <p>
      Our obligations deriving from any contracts should be considered suspended
      during the period in which Force Majeure remains in effect and we will be
      given an extension of the period in which to fulfil these obligations by
      an amount of time we shall communicate to you, not being less than the
      time that the situation of Force Majeure lasted.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>TERMINATION</h3>
          <p>
      We reserve the right to refuse the use of Services immediately in case
      your conduct is deemed by us to be in contravention of applicable acts,
      laws, rules and regulations or these Terms and Conditions. For change in
      law specifically, we reserve our rights to suspend our obligations under
      any contract indefinitely, and / or provide Services under revised Terms
      and Conditions.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>DISPUTE RESOLUTION AND JURISDICTION</h3>
            <p>
      The use of our Website and the contract between you and the Company shall
      be governed by the laws applicable in India, without regard to the
      conflict of law rules. Any dispute relating to the use of our Services
      will be only resolved exclusively in the Courts at Mumbai, India but not
      in any other Court. All disputes will be subject to arbitration at Mumbai
      by a sole arbitrator appointed by the Company under the Arbitration and
      Conciliation Act, 1996.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>SURVIVAL</h3>
            <p>
      Even after termination, certain obligations mentioned under Covenants,
      Liability, Indemnity, Intellectual Property, Dispute Resolution will
      continue and survive termination.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>SEVERABILITY</h3>
            <p>
      If any provision of these Terms of Use is deemed invalid, unlawful, void
      or for any other reason unenforceable, then that provision shall be deemed
      severable from these Terms of Use and shall not affect the validity and
      enforceability of any of the remaining provisions.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>WAIVER</h3>
            <p>
      No provision of these Terms of Use shall be deemed to be waived and no
      breach excused, unless such waiver or consent shall be in writing and
      signed by Us. Any consent by the Company to, or a waiver by Us of any
      breach by Other Parties, whether expressed or implied, shall not
      constitute consent to, waiver of, or excuse for any other different or
      subsequent breach.
    </p>
    <p>&nbsp;</p>
			  </div>

			  <div class="terms-text">
                <h3>CONTACT US</h3>
            <p>
      If you have any comments, query or grievances regarding the Services,
      Terms and Conditions and Privacy Policy, you may contact Us at&nbsp;our
      email id
      <b>support@pharmgo.in</b> . Please note that upon lodging of a complaint,
      a ticket number shall be given to you by which you may track the status of
      your complaint.
    </p> 
			  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Content -->
    <layout-footer></layout-footer>
  </div>
</template>
