<template>
        <div class="main-wrapper">
            <header-pharmacy-admin></header-pharmacy-admin>
            <sidebar-pharmacy-admin></sidebar-pharmacy-admin>
     <!-- Page Wrapper -->
     <div class="page-wrapper">
        <div class="content container-fluid">
        
            <!-- Invoice Container -->
            <div class="invoice-container">
                
                <div class="row">
                    <div class="col-sm-6 m-b-20">
                        <img alt="Logo" class="inv-logo img-fluid" src="../../assets/pharmacy_img/logo.png">
                    </div>
                    <div class="col-sm-6 m-b-20">
                        <div class="invoice-details">
                            <h3 class="text-uppercase">Invoice 20169998</h3>
                            <ul class="list-unstyled mb-0">
                                <li>Date: <span>14-5-2020</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 m-b-20">
                        <ul class="list-unstyled mb-0">
                            <li>Doccure Hospital</li>
                            <li>3864 Quiet Valley Lane,</li>
                            <li>Sherman Oaks, CA, 91403</li>
                            <li>GST No:</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-lg-7 col-xl-8 m-b-20">
                        <h6>Invoice to</h6>
                        <ul class="list-unstyled mb-0">
                            <li><h5 class="mb-0"><strong>Charlene Reed</strong></h5></li>
                            <li>4417 Goosetown Drive</li>
                            <li>Taylorsville, NC, 28681</li>
                            <li>United States</li>
                            <li>8286329170</li>
                            <li><a href="#">charlenereed@example.com</a></li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-lg-5 col-xl-4 m-b-20">
                        <h6>Payment Details</h6>
                        <ul class="list-unstyled invoice-payment-details mb-0">
                            <li><h5>Total Due: <span class="text-right">$200</span></h5></li>
                            <li>Bank name: <span>Profit Bank Europe</span></li>
                            <li>Country: <span>United Kingdom</span></li>
                            <li>City: <span>London E1 8BF</span></li>
                            <li>Address: <span>3 Goodman Street</span></li>
                            <li>IBAN: <span>KFH37784028476740</span></li>
                            <li>SWIFT code: <span>BPT4E</span></li>
                        </ul>
                    </div>
                </div>
                
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ITEM</th>
                                <th class="d-none d-sm-table-cell">DESCRIPTION</th>
                                <th class="text-nowrap">UNIT COST</th>
                                <th>QTY</th>
                                <th>TOTAL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>medicine1</td>
                                <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                <td>$10</td>
                                <td>2</td>
                                <td>$10</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>medicine2</td>
                                <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                <td>$10</td>
                                <td>1</td>
                                <td>$10</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>medicine3</td>
                                <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                <td>$90</td>
                                <td>1</td>
                                <td>$90</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>medicine4</td>
                                <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                <td>$70</td>
                                <td>1</td>
                                <td>$70</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>medicine5</td>
                                <td class="d-none d-sm-table-cell">Lorem ipsum dolor sit amet, consectetur adipiscing elit</td>
                                <td>70</td>
                                <td>1</td>
                                <td>$70</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div>
                    <div class="row invoice-payment">
                        <div class="col-sm-7">
                        </div>
                        <div class="col-sm-5">
                            <div class="m-b-20">
                                <h6>Total due</h6>
                                <div class="table-responsive no-border">
                                    <table class="table mb-0">
                                        <tbody>
                                            <tr>
                                                <th>Subtotal:</th>
                                                <td class="text-right">$250</td>
                                            </tr>
                                            <tr>
                                                <th>Tax: <span class="text-regular">(25%)</span></th>
                                                <td class="text-right">$50</td>
                                            </tr>
                                            <tr>
                                                <th>Total:</th>
                                                <td class="text-right text-primary"><h5>$200</h5></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="invoice-info">
                        <h5>Other information</h5>
                        <p class="text-muted mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero.</p>
                    </div>
                </div>
                
            </div>
            <!-- /Invoice Container -->
            
        </div>			
    </div>
    <!-- /Page Wrapper -->
            </div>
</template>
<script>
    export default {
    mounted() {
        $(document).on('click', '#toggle_btn', function() {
		if($('body').hasClass('mini-sidebar')) {
			$('body').removeClass('mini-sidebar');
			$('.subdrop + ul').slideDown();
		} else {
			$('body').addClass('mini-sidebar');
			$('.subdrop + ul').slideUp();
		}
		// setTimeout(function(){ 
		// 	mA.redraw();
		// 	mL.redraw();
		// }, 300);
		return false;
	});
	$(document).on('mouseover', function(e) {
		e.stopPropagation();
		if($('body').hasClass('mini-sidebar') && $('#toggle_btn').is(':visible')) {
			var targ = $(e.target).closest('.sidebar').length;
			if(targ) {
				$('body').addClass('expand-menu');
				$('.subdrop + ul').slideDown();
			} else {
				$('body').removeClass('expand-menu');
				$('.subdrop + ul').slideUp();
			}
			return false;
		}
    });
},
    }
</script>