<template>
	<div class="main-wrapper">
		<layout-header4></layout-header4>

		<!-- Home Banner  -->
			<div class="home-banner">
				<div class="dot-slider slider">
					<!-- Banner Images -->
					<div class="profile-widget">
						<div class="pro-img">
							<a href="javascript:void(0);">
								<img class="img-fluid" src="../assets/img/slider/slider-4.jpg" alt="">
							</a>
						</div>
					</div>
					<!-- /Banner Images -->
					<!-- Banner Images -->
					<div class="profile-widget">
						<div class="pro-img">
							<a href="javascript:void(0);">
								<img class="img-fluid" src="../assets/img/slider/slider-5.jpg" alt="">
							</a>
						</div>
					</div>
					<!-- /Banner Images -->
					<!-- Banner Images -->
					<div class="profile-widget">
						<div class="pro-img">
							<a href="javascript:void(0);">
								<img class="img-fluid" src="../assets/img/slider/slider-6.jpg" alt="">
							</a>
						</div>
					</div>
					<!-- /Banner Images -->
					<!-- Banner Images -->
					<div class="profile-widget">
						<div class="pro-img">
							<a href="javascript:void(0);">
								<img class="img-fluid" src="../assets/img/slider/slider-7.jpg" alt="">
							</a>
						</div>
					</div>
					<!-- /Banner Images -->
				</div>		
				<div class="container">
					<div class="banner-wrapper doc-form">
						<div class="app-form">
							<h2>Search Doctor, <br> <span class="header-highlight">Make an Appointment</span></h2>
							<form action="#" class="doctor-search-form">
								<div class="row">
									<div class="col-md-6 col-lg-6">
										<div class="form-group">
											<div class="form-custom">
												<input type="text" class="form-control" value="" placeholder="Search Location">
												<i class="far fa-compass"></i>
											</div>
										</div>
									</div>
									<div class="col-md-6 col-lg-6">
										<div class="form-group">
											<div class="form-custom">
												<input type="text" class="form-control" value="" placeholder="Gender">
												<i class="far fa-smile"></i>
											</div>
										</div>
									</div>
									<div class="col-md-7 col-lg-8">
										<div class="form-group">
											<div class="form-custom">
												<input type="text" class="form-control" value="" placeholder="Department">
												<i class="fas fa-user-check"></i>
											</div>
										</div>
									</div>
									<div class="col-md-5 col-lg-4">
										<router-link to="/doctor/search" class="btn banner-btn">MAKE APPOINTMENT</router-link>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>			
			<!-- /Home Banner -->

			<!-- Category Section -->
			<section class="category-sec">
				<div class="container">
					<div class="row">
						<div class="col-md-4">
							<div class="set-category">
								<div class="d-flex align-items-center">
									<div class="img-holder">
										<a href="javascript:void(0);"><img src="../assets/img/icons/set-cat-01.png" alt="" /></a>
									</div>
									<div class="info-holder">
										<h3>Visit a Doctor</h3>
										<p>We hire the best specialists to deliver top-notch diagnostic services for you.</p>
										<router-link to="/patient/booking" class="book-now">Book Now <i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<h1>01</h1>
							</div>
						</div>
						<div class="col-md-4">
							<div class="set-category light">
								<div class="d-flex align-items-center">
									<div class="img-holder">
										<a href="javascript:void(0);"><img src="../assets/img/icons/set-cat-02.png" alt="" /></a>
									</div>
									<div class="info-holder">
										<h3>Find a Pharmacy</h3>
										<p>We provide the a wide range of medical services, so every person could have the opportunity.</p>
										<router-link to="/patient/booking" class="book-now">Book Now <i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<h1>02</h1>
							</div>
						</div>
						<div class="col-md-4">
							<div class="set-category lighter">
								<div class="d-flex align-items-center">
									<div class="img-holder">
										<a href="javascript:void(0);"><img src="../assets/img/icons/set-cat-03.png" alt="" /></a>
									</div>
									<div class="info-holder">
										<h3>Find a Lab</h3>
										<p>We use the first-class medical equipment for timely diagnostics of various diseases.</p>
										<router-link to="/patient/booking" class="book-now">Book Now <i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<h1>03</h1>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Category Section -->

			<!-- Clinic and Specialities -->
			<section class="clinic-section">
				<div class="container">
					<div class="sec-header">
						<h2>Clinic and Specialities</h2>
						<span class="bottom-border"></span>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="clinic-slider slider">
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-01.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-01.png"/>
										</a>
										<h4>Orthopedic</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-02.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-02.png"/>
										</a>
										<h4>Urology</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-03.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-03.png"/>
										</a>
										<h4>Cardiologist</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-04.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-04.png"/>
										</a>
										<h4>Neurology</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-05.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-05.png"/>
										</a>
										<h4>Dentist</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-06.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-06.png"/>
										</a>
										<h4>Laboratry</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-07.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-07.png"/>
										</a>
										<h4>MRI Scans</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-08.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-08.png"/>
										</a>
										<h4>Primary Checkup</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-09.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-09.png"/>
										</a>
										<h4>Testing</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-10.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-10.png"/>
										</a>
										<h4>Pediatrics</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-01.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-01.png"/>
										</a>
										<h4>Orthopedic</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget">
									<div class="brower-box">
										<a href="javascript:void(0);">
											<img class="img-fluid" alt="User Image" src="../assets/img/icons/clinic-02.png">
											<img class="img-fluid hover" alt="" src="../assets/img/icons/clinic-hover-02.png"/>
										</a>
										<h4>Urology</h4>
										<p>124 <span>Doctors</span></p>
										<router-link to="/doctor/profile" class="arrow"><i class="fas fa-chevron-right"></i></router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Clinic and Specialities -->

			<!-- /Browse by Specialities -->
			<section class="browse-speciality">
				<div class="container">
					<div class="sec-header">
						<h2>Browse by Specialities</h2>
						<span class="bottom-border"></span>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="browse-slider slider">
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-01.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-02.png" alt="" />
										<h3>UROLOGY</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-01.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-02.png" alt="" />
										<h3>UROLOGY</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-02.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-11.png" alt="" />
										<h3>Orthopedic</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-02.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-11.png" alt="" />
										<h3>Orthopedic</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-03.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-03.png" alt="" />
										<h3>Cardiologist</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-03.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-03.png" alt="" />
										<h3>Cardiologist</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-04.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-12.png" alt="" />
										<h3>Dentist</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-04.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-12.png" alt="" />
										<h3>Dentist</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-05.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-13.png" alt="" />
										<h3>Neurology</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-05.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-13.png" alt="" />
										<h3>Neurology</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-01.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-03.png" alt="" />
										<h3>Cardiologist</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
								<div class="profile-widget browse-widget">
									<img src="../assets/img/icons/browse-01.jpg" alt="" />
									<div class="overlay">
										<img src="../assets/img/icons/clinic-03.png" alt="" />
										<h3>Cardiologist</h3>
										<router-link to="/doctor/profile" class="arrows"><i class="fas fa-long-arrow-alt-right"></i></router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Browse by Specialities -->

			<!-- Select Sections-->
			<section class="patient-select">
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="select-box">
								<div class="d-flex align-items-center">
									<div class="image-holder">
										<span>
											<img src="../assets/img/icons/doct-01.png" alt="" />
										</span>
									</div>
									<div>
										<h2>ARE YOU A DOCTOR?</h2>
										<p>The service allows you to get maximum visibility online and to manage 
											appointments and contacts coming from the site, in a simple and fast way.</p>
										<router-link to="/patient/booking" class="book-now">Book Now</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6">							
							<div class="select-box theme">
								<div class="d-flex align-items-center">
									<div class="image-holder">
										<span>
											<img src="../assets/img/icons/doct-02.png" alt="" />
										</span>
									</div>
									<div>
										<h2>ARE YOU A DOCTOR?</h2>
										<p>Choosing a specialist has never been so fast! You can filter search 
											results by location and medical specialization, and book medical examination online.</p>
										<router-link to="/patient/booking" class="book-now">Book Now</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Select Sections-->

			<!-- Book Doctors-->
			<section class="book-doctor">
				<div class="container">					
					<div class="sec-header">
						<h2>Book Our Best Doctor</h2>
						<span class="bottom-border"></span>
					</div>
					<div class="row">
						<div class="col-md-12">
							<div class="book-slider slider">
								<div class="profile-widget">
									<router-link to="/doctor/profile">
										<img src="../assets/img/slider/book-doc-01.jpg" alt="" />
									</router-link>
									<div class="pro-content">
										<div  class="row row-sm">	
											<div class="col-md-6">							
												<span class="amt">$20 - $50</span>
											</div>
											<div class="col-md-6 text-right">
												<a href="#" class="star"><i class="fas fa-star"></i></a>	
											</div>
										</div>
										<div class="provider-info">
											<h3><router-link to="/doctor/profile">Dr. Pullman</router-link></h3>
											<div>
												<h5>PSICOLOGIST </h5>
												<div class="rating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">3.5</span>
												</div>
											</div>
											<a href="#" class="side-arrow">
												<i class="fas fa-chevron-right"></i>
											</a>
											<div class="content-info">
												<ul class="available-info">
													<li>
														<i class="fas fa-map-marker-alt"></i> Georgia, USA
													</li>
													<li>
														<i class="far fa-clock"></i> 450 Consultations
													</li>
												</ul>
												<div class="row row-sm">
													<div class="col-6">
														<a href="javascript:void(0);" class="btn view-btn" tabindex="0">View Profile</a>
													</div>
													<div class="col-6">
														<router-link to="/patient/booking" class="btn book-btn" tabindex="0">Book Now</router-link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="profile-widget">
									<router-link to="/doctor/profile">
										<img src="../assets/img/slider/book-doc-01.jpg" alt="" />
									</router-link>
									<div class="pro-content">
										<div  class="row row-sm">	
											<div class="col-md-6">							
												<span class="amt">$20 - $50</span>
											</div>
											<div class="col-md-6 text-right">
												<a href="#" class="star"><i class="fas fa-star"></i></a>	
											</div>
										</div>
										<div class="provider-info">
											<h3><router-link to="/doctor/profile">Dr. Pullman</router-link></h3>
											<div>
												<h5>PSICOLOGIST </h5>
												<div class="rating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">3.5</span>
												</div>
											</div>
											<a href="#" class="side-arrow">
												<i class="fas fa-chevron-right"></i>
											</a>
											<div class="content-info">
												<ul class="available-info">
													<li>
														<i class="fas fa-map-marker-alt"></i> Georgia, USA
													</li>
													<li>
														<i class="far fa-clock"></i> 450 Consultations
													</li>
												</ul>
												<div class="row row-sm">
													<div class="col-6">
														<router-link to="/doctor/profile" class="btn view-btn" tabindex="0">View Profile</router-link>
													</div>
													<div class="col-6">
														<router-link to="/patient/booking" class="btn book-btn" tabindex="0">Book Now</router-link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="profile-widget">
									<a href="#">
										<img src="../assets/img/slider/book-doc-01.jpg" alt="" />
									</a>
									<div class="pro-content">
										<div  class="row row-sm">	
											<div class="col-md-6">							
												<span class="amt">$20 - $50</span>
											</div>
											<div class="col-md-6 text-right">
												<a href="#" class="star"><i class="fas fa-star"></i></a>	
											</div>
										</div>
										<div class="provider-info">
											<h3><router-link to="/doctor/profile">Dr. Pullman</router-link></h3>
											<div>
												<h5>PSICOLOGIST </h5>
												<div class="rating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">3.5</span>
												</div>
											</div>
											<a href="#" class="side-arrow">
												<i class="fas fa-chevron-right"></i>
											</a>
											<div class="content-info">
												<ul class="available-info">
													<li>
														<i class="fas fa-map-marker-alt"></i> Georgia, USA
													</li>
													<li>
														<i class="far fa-clock"></i> 450 Consultations
													</li>
												</ul>
												<div class="row row-sm">
													<div class="col-6">
														<router-link to="/doctor/profile" class="btn view-btn" tabindex="0">View Profile</router-link>
													</div>
													<div class="col-6">
														<router-link to="/patient/booking" class="btn book-btn" tabindex="0">Book Now</router-link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="profile-widget">
									<a href="#">
										<img src="../assets/img/slider/book-doc-01.jpg" alt="" />
									</a>
									<div class="pro-content">
										<div  class="row row-sm">	
											<div class="col-md-6">							
												<span class="amt">$20 - $50</span>
											</div>
											<div class="col-md-6 text-right">
												<a href="#" class="star"><i class="fas fa-star"></i></a>	
											</div>
										</div>
										<div class="provider-info">
											<h3><router-link to="/doctor/profile">Dr. Pullman</router-link></h3>
											<div>
												<h5>PSICOLOGIST </h5>
												<div class="rating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">3.5</span>
												</div>
											</div>
											<a href="#" class="side-arrow">
												<i class="fas fa-chevron-right"></i>
											</a>
											<div class="content-info">
												<ul class="available-info">
													<li>
														<i class="fas fa-map-marker-alt"></i> Georgia, USA
													</li>
													<li>
														<i class="far fa-clock"></i> 450 Consultations
													</li>
												</ul>
												<div class="row row-sm">
													<div class="col-6">
														<router-link to="/doctor/profile" class="btn view-btn" tabindex="0">View Profile</router-link>
													</div>
													<div class="col-6">
														<router-link to="/patient/booking" class="btn book-btn" tabindex="0">Book Now</router-link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="profile-widget">
									<a href="#">
										<img src="../assets/img/slider/book-doc-01.jpg" alt="" />
									</a>
									<div class="pro-content">
										<div  class="row row-sm">	
											<div class="col-md-6">							
												<span class="amt">$20 - $50</span>
											</div>
											<div class="col-md-6 text-right">
												<a href="#" class="star"><i class="fas fa-star"></i></a>	
											</div>
										</div>
										<div class="provider-info">
											<h3><router-link to="/doctor/profile">Dr. Pullman</router-link></h3>
											<div>
												<h5>PSICOLOGIST </h5>
												<div class="rating">
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star filled"></i>
													<i class="fas fa-star"></i>
													<span class="d-inline-block average-rating">3.5</span>
												</div>
											</div>
											<a href="#" class="side-arrow">
												<i class="fas fa-chevron-right"></i>
											</a>
											<div class="content-info">
												<ul class="available-info">
													<li>
														<i class="fas fa-map-marker-alt"></i> Georgia, USA
													</li>
													<li>
														<i class="far fa-clock"></i> 450 Consultations
													</li>
												</ul>
												<div class="row row-sm">
													<div class="col-6">
														<router-link to="/doctor/profile" class="btn view-btn" tabindex="0">View Profile</router-link>
													</div>
													<div class="col-6">
														<router-link to="/patient/booking" class="btn book-btn" tabindex="0">Book Now</router-link>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="row justify-content-center mt-4">
						<router-link to="/doctor/profile" class="view-btn">View More</router-link>
					</div>
				</div>
			</section>
			<!-- /Book Doctors-->

			<!-- Available Features-->
			<section class="aval-features">
				<div class="custom-contain">
					<div class="row">
						<div class="col-lg-5 col-md-6 feature">
							<div class="info-box">
								<h2>Availabe Features in Our Clinic</h2>
								<p>Meet our Experts & Book Online</p>
								<router-link to="/doctor/profile" class="view-btn">View More</router-link>
							</div>
						</div>
						<div class="col-lg-7 col-md-6 pl-0">
							<div class="aval-slider slider">
								<div class="aval-widget">
									<div class="features-img">
										<img src="../assets/img/clinic/spec-01.jpg" alt="" />
										<p>Operation</p>
									</div>
								</div>
								<div class="aval-widget">
									<div class="features-img">
										<img src="../assets/img/clinic/spec-02.jpg" alt="" />
										<p>Medical</p>
									</div>
								</div>
								<div class="aval-widget">
									<div class="features-img">
										<img src="../assets/img/clinic/spec-03.jpg" alt="" />
										<p>Patient Ward</p>
									</div>
								</div>
								<div class="aval-widget">
									<div class="features-img">
										<img src="../assets/img/clinic/spec-01.jpg" alt="" />
										<p>Operation</p>
									</div>
								</div>
								<div class="aval-widget">
									<div class="features-img">
										<img src="../assets/img/clinic/spec-02.jpg" alt="" />
										<p>Medical</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Available Features-->

			<!-- Blog Section-->
			<section class="blogs-section">
				<div class="container">			
					<div class="sec-header">
						<h2>Our Latest Blogs</h2>
						<span class="bottom-border"></span>
					</div>		
					<div class="row">
						<div class="col-md-6 col-lg-3">
							<div class="blog-wrapper">
								<div class="wrap-image">
									<router-link to="/doctor/blog-details">
										<img src="../assets/img/blog/blog-wrap-01.jpg" alt="" />
									</router-link>
								</div>
								<div class="wrap-content">
									<div class="d-flex">
										<span class="sp-title">Urology</span>
										<div class="post-author">
											<router-link to="/doctor/profile">
												<img src="../assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author"> 
												<span>Ruby Perrin</span>
											</router-link>
										</div>
										<p class="date-cart">25 <span>Jan 2021</span></p>
									</div>
									<div class="wrap-content-body">
										<h3><router-link to="/doctor/blog-details">Doccure – Making your clinic painless visit?</router-link></h3>
										<p>Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
										<router-link to="/doctor/blog-details" class="read-txt">Read More</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3">
							<div class="blog-wrapper">
								<div class="wrap-image">
									<router-link to="/doctor/blog-details">
										<img src="../assets/img/blog/blog-wrap-02.jpg" alt="" />
									</router-link>
								</div>
								<div class="wrap-content">
									<div class="d-flex">
										<span class="sp-title">Urology</span>
										<div class="post-author">
											<router-link to="/doctor/profile">
												<img src="../assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author"> 
												<span>Ruby Perrin</span>
											</router-link>
										</div>
										<p class="date-cart">25 <span>Jan 2021</span></p>
									</div>
									<div class="wrap-content-body">
										<h3><router-link to="/doctor/blog-details">Doccure – Making your clinic painless visit?</router-link></h3>
										<p>Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
										<router-link to="/doctor/blog-details" class="read-txt">Read More</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3">
							<div class="blog-wrapper">
								<div class="wrap-image">
									<router-link to="/doctor/blog-details">
										<img src="../assets/img/blog/blog-wrap-03.jpg" alt="" />
									</router-link>
								</div>
								<div class="wrap-content">
									<div class="d-flex">
										<span class="sp-title">Urology</span>
										<div class="post-author">
											<router-link to="/doctor/profile">
												<img src="../assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author"> 
												<span>Ruby Perrin</span>
											</router-link>
										</div>
										<p class="date-cart">25 <span>Jan 2021</span></p>
									</div>
									<div class="wrap-content-body">
										<h3><router-link to="/doctor/blog-details">Doccure – Making your clinic painless visit?</router-link></h3>
										<p>Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
										<router-link to="/doctor/blog-details" class="read-txt">Read More</router-link>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-3">
							<div class="blog-wrapper">
								<div class="wrap-image">
									<router-link to="/doctor/blog-details">
										<img src="../assets/img/blog/blog-wrap-04.jpg" alt="" />
									</router-link>
								</div>
								<div class="wrap-content">
									<div class="d-flex">
										<span class="sp-title">Urology</span>
										<div class="post-author">
											<router-link to="/doctor/profile">
												<img src="../assets/img/doctors/doctor-thumb-01.jpg" alt="Post Author"> 
												<span>Ruby Perrin</span>
											</router-link>
										</div>
										<p class="date-cart">25 <span>Jan 2021</span></p>
									</div>
									<div class="wrap-content-body">
										<h3><router-link to="/doctor/blog-details">Doccure – Making your clinic painless visit?</router-link></h3>
										<p>Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do eiusmod tempor.</p>
										<router-link to="/doctor/blog-details" class="read-txt">Read More</router-link>
									</div>
								</div>
							</div>
						</div>
					</div>						
					<div class="row justify-content-center">
						<router-link to="/doctor/blog-details" class="view-btn">View More</router-link>
					</div>		
				</div>
			</section>
			<!-- /Blog Section-->

		<layout-footer4></layout-footer4>
	</div>
</template>
<script>

export default {
  
        mounted() {

  //Home pharmacy slider
	if($('.dot-slider').length > 0) {
		$('.dot-slider').slick({
			dots: true,
			autoplay:false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 1
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}
	//clinic slider
	if($('.clinic-slider').length > 0) {
		$('.clinic-slider').slick({
			dots: false,
			autoplay:false,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			rows: 2,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}   
	//browse slider
	if($('.browse-slider').length > 0) {
		$('.browse-slider').slick({
			dots: false,
			autoplay:false,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			rows: 2,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}

//book doctor slider
	if($('.book-slider').length > 0) {
		$('.book-slider').slick({
			dots: false,
			autoplay:false,
			infinite: true,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 3
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}  

	//avalable features slider
	if($('.aval-slider').length > 0) {
		$('.aval-slider').slick({
			dots: false,
			autoplay:false,
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [{
				breakpoint: 992,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 800,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 776,
					settings: {
						slidesToShow: 2
				  	}
			},
			{
				breakpoint: 567,
					settings: {
						slidesToShow: 1
					}
			}]
		});
	}   

    	},

}
</script>