<template>
    <!-- Breadcrumb -->
    <div class="breadcrumb-bar">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-md-12 col-12">
                    <nav aria-label="breadcrumb" class="page-breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><router-link to="/index">Home</router-link></li>
                            <li class="breadcrumb-item active" aria-current="page">Components</li>
                        </ol>
                    </nav>
                    <h2 class="breadcrumb-title">Components</h2>
                </div>
            </div>
        </div>
    </div>
    <!-- /Breadcrumb -->
</template>