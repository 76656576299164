<template>
  <!-- Footer -->
  <footer class="footer">
    <!-- Footer Top -->
    <div class="footer-top">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <img
                  src="../../assets/img/pharmgo-logo-crop.png"
                  alt="logo"
                  style="width: 40%"
                />
              </div>
              <div class="footer-about-content fi">
                <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> -->
                <div class="social-icon">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/PharmGoofficial/"
                        target="_blank"
                        ><i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/PharmGooffical?t=eanM3lwEPfpWozGO9ok2iw&s=08"
                        target="_blank"
                        ><i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <!-- <li>
                      <a href="#" target="_blank"
                        ><i class="fab fa-linkedin-in"></i
                      ></a>
                    </li> -->
                    <li>
                      <a
                        href="https://www.instagram.com/pharmgoofficial/"
                        target="_blank"
                        ><i class="fab fa-instagram"></i
                      ></a>
                    </li>
                    <!-- <li>
                      <a href="#" target="_blank"
                        ><i class="fab fa-dribbble"></i>
                      </a>
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <!-- <h2 class="footer-title">For Patients</h2> -->
              <!-- <ul>
								<li><router-link to="/doctor/search">Search for Doctors</router-link></li>
								<li><router-link to="/login">Login</router-link></li>
								<li><router-link to="/patient/register">Register</router-link></li>
								<li><router-link to="/patient/booking">Booking</router-link></li>
								<li><router-link to="/patient/index">Patient Dashboard</router-link></li>
							</ul> -->
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-menu">
              <!-- <h2 class="footer-title">For Doctors</h2> -->
              <!-- <ul>
								<li><router-link to="/doctor/appointments">Appointments</router-link></li>
								<li><router-link to="/patient/chat">Chat</router-link></li>
								<li><router-link to="/login">Login</router-link></li>
								<li><router-link to="/doctor/register">Register</router-link></li>
								<li><router-link to="/doctor/index">Doctor Dashboard</router-link></li>
							</ul> -->
            </div>
            <!-- /Footer Widget -->
          </div>
          <div class="col-lg-3 col-md-6">
            <!-- Footer Widget -->
            <div class="footer-widget footer-contact">
              <h2 class="footer-title">Contact Us</h2>
              <div class="footer-contact-info">
                <div class="footer-address">
                  <span><i class="fas fa-map-marker-alt"></i></span>
                  <p>
                    CENTDEAL PRIVATE LIMITED BARRACK NO. 631, <br />ROOM NO 57,
                    KARO HALL, <br />KHEMANI BUS STOP, ULHASNAGAR - 2,
                    <br />ULHASNAGAR, Thane, <br />Maharashtra, India, 421002
                  </p>
                </div>
                <p>
                  <i class="fas fa-phone-alt"></i>
                  +91 9075603483
                </p>
                <p class="mb-0">
                  <i class="fas fa-envelope"></i>
                  <strong><a>support@pharmgo.in</a></strong>
                </p>
              </div>
            </div>
            <!-- /Footer Widget -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->
    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container-fluid">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">Copyright 2022 &copy; CentDeal Private Limited. All rights reserved.</p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li>
                    <router-link to="/return-policy">Return policy</router-link>
                  </li>
                  <li>
                    <router-link to="/term-condition"
                      >Terms and Conditions</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/privacy-policy">Policy</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
